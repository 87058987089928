import React from 'react';
import * as OrganisationStore from '../../store/Organisation';
import { LabelsContext } from '../../utils/labels-context';
import FaIcon from '../../components/fa-icon';

type AuthorizationGroupProps = {
    authGroup: OrganisationStore.AuthorizationGroup;
    isDeleted: boolean;
    isEditing: boolean;
    hasChanges: boolean;
    index: number;
    onRevert: () => void;
    onChange: (authGroup: OrganisationStore.AuthorizationGroup) => void;
    onStartEdit: () => void;
    onEndEdit: () => void;
    onDelete: () => void;
    onRestore: () => void;
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
class AuthorizationGroup extends React.PureComponent<AuthorizationGroupProps> {
    public render() {
        const isDeleted = this.props.isDeleted;
        const isEditing = this.props.isEditing;
        const index = this.props.index;
        const authGroup = this.props.authGroup;
        const hasChanges = this.props.hasChanges;

        return (
            <LabelsContext.Consumer>
                {(labels) =>
                    isEditing ? (
                        <tr key={authGroup.id} className={isDeleted ? 'group-row deletedAuthGroup' : 'group-row authGroup'}>
                            <td className="index-column">{index + 1}</td>
                            <td className="text-column">
                                <input autoComplete="off" style={{ maxWidth: '100%' }} type="text" defaultValue={authGroup.displayName} onChange={(e) => this.updateGroup(e, authGroup, 'displayName')} />
                            </td>
                            <td className="checkbox-column">
                                <input type="checkbox" readOnly={false} checked={authGroup.isApplicantGroup} onChange={(e) => this.updateGroup(e, authGroup, 'isApplicantGroup', !authGroup.isApplicantGroup)} />
                                {authGroup.isApplicantGroup ? labels.yes : labels.no}
                            </td>
                            <td className="checkbox-column">
                                <input type="checkbox" readOnly={false} checked={authGroup.isAdmin} onChange={(e) => this.updateGroup(e, authGroup, 'isAdmin', !authGroup.isAdmin)} />
                                <label>{authGroup.isAdmin ? labels.yes : labels.no}</label>
                            </td>
                            <td className="text-column">
                                <input autoComplete="off" style={{ maxWidth: '100%' }} type="text" defaultValue={authGroup.remoteId} onChange={(e) => this.updateGroup(e, authGroup, 'remoteId')} />
                            </td>
                            <td>
                                <FaIcon className="iconButton" name="check" regular={false} onClick={() => this.endEditAuthGroup()}></FaIcon>
                                {hasChanges && <FaIcon className="iconButton" name="undo" regular={false} onClick={() => this.revertAuthorizationGroup()} />}
                            </td>
                        </tr>
                    ) : (
                        <tr key={authGroup.id} className={isDeleted ? 'group-row deletedAuthGroup' : 'group-row authGroup'}>
                            <td className="index-column">{index + 1}</td>
                            <td className="text-column" onClick={() => isDeleted || this.startEditAuthGroup()}>
                                {authGroup.displayName}
                            </td>
                            <td className="checkbox-column" onClick={() => isDeleted || this.startEditAuthGroup()}>
                                {authGroup.isApplicantGroup ? labels.yes : labels.no}
                            </td>
                            <td className="checkbox-column" onClick={() => isDeleted || this.startEditAuthGroup()}>
                                {authGroup.isAdmin ? labels.yes : labels.no}
                            </td>
                            <td className="text-column" onClick={() => isDeleted || this.startEditAuthGroup()}>
                                {authGroup.remoteId}
                            </td>
                            <td>
                                {isDeleted ? (
                                    <FaIcon className="iconButton" name="trash-restore" regular={false} onClick={() => this.restoreAuthorizationGroup()}></FaIcon>
                                ) : (
                                    <FaIcon className="iconButton" name="trash-alt" regular={true} onClick={() => this.deleteAuthorizationGroup()}></FaIcon>
                                )}
                                {hasChanges && <FaIcon className="iconButton" name="undo" regular={false} onClick={() => this.revertAuthorizationGroup()} />}
                            </td>
                        </tr>
                    )
                }
            </LabelsContext.Consumer>
        );
    }

    private updateGroup(event: React.ChangeEvent, authGroup: OrganisationStore.AuthorizationGroup, key: string, updateTo: any = undefined) {
        const value = updateTo !== undefined ? updateTo : (event.target as HTMLInputElement).value;
        if (this.props.onChange) {
            this.props.onChange({
                ...this.props.authGroup,
                [key]: value,
            });
        }
    }

    private startEditAuthGroup() {
        if (this.props.onStartEdit) {
            this.props.onStartEdit();
        }
    }

    private endEditAuthGroup() {
        if (this.props.onEndEdit) {
            this.props.onEndEdit();
        }
    }

    private deleteAuthorizationGroup() {
        if (this.props.onDelete) {
            this.props.onDelete();
        }
    }

    private revertAuthorizationGroup() {
        if (this.props.onRevert) {
            this.props.onRevert();
        }
    }

    private restoreAuthorizationGroup() {
        if (this.props.onRestore) {
            this.props.onRestore();
        }
    }
}

export default AuthorizationGroup;
