import React, { useEffect, useState, VFC } from 'react';
import Axios from 'axios';
import { InfoBox } from '@in/component-library';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import { addThousandSeparator } from '../../utils/numbers';
import cn from 'classnames';

import './constraint.scss';
import { reAuthenticate } from '../input-schema/reauthenticate';
import { Payload } from 'recharts/types/component/DefaultLegendContent';
import { devTest } from '../../utils/devtest';

interface ConstraintProps {
    loginTimeStamp: number;
    width: number;
}

interface ConstraintType {
    accountName: string;
    available: number;
    reserved: number;
    total: number;
    type: number;
}

const Constraint: VFC<ConstraintProps> = (props) => {
    const [constraints, setConstraints] = useState<any>({ constraints: [] });

    useEffect(() => {
        const source = Axios.CancelToken.source();
        Axios.get('/api/constraint', { cancelToken: source.token })
            .then((response) => {
                const constraints = response.data as ConstraintType[];
                if (constraints.length > 0) {
                    setConstraints({ constraints: constraints });
                } else {
                    if (devTest()) {
                        setConstraints({ constraints: [{ accountName: '9977-13', type: 'BackingGuarantee', total: 37500000.0, reserved: 8000000.0, available: 11500000.0 }] });
                    }
                }
            })
            .catch((error) => {
                console.error(error);
                if (error.status === undefined) {
                    reAuthenticate(props.loginTimeStamp);
                }
            });

        return () => {
            source.cancel();
        };
    }, []);

    return (
        <div>
            <InfoBox title="Rammer">
                {constraints.constraints &&
                    constraints.constraints.map((c) => {
                        const used = c.total - c.available - c.reserved;

                        const data = [
                            {
                                name: `Innvilgede vekstgarantier (${addThousandSeparator(used)} NOK)`,
                                value: (used * 100) / c.total,
                                color: '#ED3426',
                            },
                            {
                                name: `Innmeldte vekstgarantier til vurdering (${addThousandSeparator(c.reserved)} NOK)`,
                                value: (c.reserved * 100) / c.total,
                                color: '#7AE1BF',
                            },
                            {
                                name: 'Tilgjengelig ramme',
                                value: (c.available * 100) / c.total,
                                color: '#C5C5C5',
                            },
                        ];
                        const formatTooltip = (value: any, name: string) => [`${value.toFixed(2)}%`, `${name.split('(')[0]}`];

                        interface PayloadExtended extends Payload {
                            title: string;
                        }
                        const legendPayload: PayloadExtended[] = [
                            { title: 'Innvilgede vekstgarantier', value: used, type: 'rect', id: 'ID01', color: data[0].color },
                            { title: 'Innmeldte vekstgarantier til vurdering', value: c.reserved, type: 'rect', id: 'ID02', color: data[1].color },
                            { title: 'Tilgjengelig ramme', value: c.available, type: 'rect', id: 'ID03', color: data[2].color },
                        ];

                        return (
                            <div key={c.accountName}>
                                <div>Total utlånsramme: {addThousandSeparator(c.total)} NOK</div>
                                <ResponsiveContainer height={300} className={cn('chart-container')}>
                                    <PieChart className="pieChart">
                                        <Pie data={data} innerRadius={70} outerRadius={100} fill="#ED3426" dataKey="value" nameKey="name" paddingAngle={1}>
                                            {data && data.map((entry) => <Cell key={entry.name} fill={entry.color} />)}
                                        </Pie>
                                        <Tooltip formatter={formatTooltip} />
                                    </PieChart>
                                </ResponsiveContainer>
                                <div className="legendList">
                                    <table className="table">
                                        {legendPayload.map((payloadItem: PayloadExtended) => {
                                            return (
                                                <tbody key={payloadItem.id}>
                                                    <tr>
                                                        <td className="squareWrapper">
                                                            <div style={{ backgroundColor: payloadItem.color }} className="square"></div>
                                                        </td>
                                                        <td className="leadText">{payloadItem.title}:</td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td className="leadText last">{addThousandSeparator(payloadItem.value)} NOK</td>
                                                    </tr>
                                                </tbody>
                                            );
                                        })}
                                    </table>
                                </div>
                            </div>
                        );
                    })}
            </InfoBox>
        </div>
    );
};

export default Constraint;
