import moment from 'moment';
import 'moment/locale/nb';
import { ILoggedOnUser } from '../../utils/auth-context';
import { ISchema, IStoredField, isValue, isGroup, IGroup, IStoredSchema } from './input-schema';
import { cloneDeep } from 'lodash';
import { Base64 } from 'js-base64';
import { FormikValues } from 'formik';

const logData = true;

export function createPayLoad(_schema: ISchema, values: FormikValues, loggedOnUser: ILoggedOnUser): IStoredSchema | undefined {
    const mapOutSchema: ISchema = cloneDeep(_schema);
    let groupId: string;
    if (loggedOnUser.user !== undefined && loggedOnUser.user.groups !== undefined && loggedOnUser.user.groups.length > 0) {
        groupId = loggedOnUser.user.groups[0].id;

        const fields: IStoredField[] = [];

        mapOutSchema.pages.forEach((page, pageIndex) => {
            page.values.forEach((field) => {
                if (isValue(field)) {
                    const value = mapValue(field as any, values[(field as any).id]);
                    const displayValue = mapDisplayValue(field as any, values[(field as any).id]);
                    const numericValue = mapNumericValue(field as any, values[(field as any).id]);
                    fields.push({
                        id: (field as any).id,
                        groupId: (field as any).groupid,
                        pageId: pageIndex,
                        format: (field as any).format !== undefined ? (field as any).format.type : '',
                        label: (field as any).label,
                        type: (field as any).type,
                        suffix: (field as any).suffix,
                        value: Base64.encode(value.toString().replace(/[{}"]/g, '')),
                        numericValue: numericValue,
                        displayValue: displayValue,
                    });
                } else if (isGroup(field)) {
                    if (field.rows !== undefined) {
                        for (let i = 0; i < field.rows; i++) {
                            (field as IGroup).values.forEach((groupElement) => {
                                const value = mapValue(groupElement as any, values[(field as any).id + '_' + i + '_' + groupElement.id]);
                                const numericValue = mapNumericValue(groupElement as any, values[(field as any).id + '_' + i + '_' + groupElement.id]);
                                fields.push({
                                    id: field.id + '_' + i + '_' + groupElement.id,
                                    groupId: field.id,
                                    pageId: pageIndex,
                                    groupRow: i,
                                    format: (groupElement as any).format !== undefined ? (groupElement as any).format.type : '',
                                    label: (groupElement as any).label,
                                    type: (groupElement as any).type,
                                    suffix: (groupElement as any).suffix,
                                    value: Base64.encode(value.toString().replace(/[{}"]/g, '')),
                                    numericValue: numericValue,
                                });
                            });
                        }
                    } else {
                        (field as IGroup).values.forEach((groupElement) => {
                            let standaloneid = false;
                            if (groupElement.standaloneid === true) {
                                standaloneid = true;
                            }
                            let value;
                            let numericValue;

                            if (standaloneid === false) {
                                value = mapValue(groupElement as any, values[(field as any).id + '_' + groupElement.id]);
                                numericValue = mapNumericValue(groupElement as any, values[(field as any).id + '_' + groupElement.id]);
                                fields.push({
                                    id: field.id + '_' + groupElement.id,
                                    groupId: field.id,
                                    pageId: pageIndex,
                                    format: (groupElement as any).format !== undefined ? (groupElement as any).format.type : '',
                                    label: (groupElement as any).label,
                                    type: (groupElement as any).type,
                                    suffix: (groupElement as any).suffix,
                                    value: Base64.encode(value.toString().replace(/[{}"]/g, '')),
                                    numericValue: numericValue,
                                });
                            } else {
                                // Standalone can have a forced payloadkey in order to map a paired (visible/invisible) field.
                                // Invisibles are not rendered, so there will only be one mapping to the payloadkey for all such pairs.
                                // Mapping it back much consider the type for choosing the correct field of the pair.
                                value = mapValue(groupElement as any, values[groupElement.id]);
                                numericValue = mapNumericValue(groupElement as any, values[groupElement.id]);

                                if (!(groupElement.removefrompayload !== undefined && groupElement.removefrompayload === true)) {
                                    fields.push({
                                        id: groupElement.payloadkey !== undefined ? groupElement.payloadkey : groupElement.id,
                                        pageId: pageIndex,
                                        format: (groupElement as any).format !== undefined ? (groupElement as any).format.type : '',
                                        label: (groupElement as any).label,
                                        type: (groupElement as any).type,
                                        suffix: (groupElement as any).suffix,
                                        value: Base64.encode(value.toString().replace(/[{}"]/g, '')),
                                        numericValue: numericValue,
                                    });
                                }
                            }
                        });
                    }
                }
            });
            (page as any).buttons = undefined;
        });

        const saveSchema: IStoredSchema = {
            metadata: {
                transmitted: Date.now(),
                groupId: groupId,
            },
            fields: fields,
        };

        if (logData === true) {
            const saveSchemaLog: IStoredSchema = cloneDeep(saveSchema);
            saveSchemaLog.fields.forEach((scLog) => {
                scLog.value = Base64.decode(scLog.value);
            });
            // nice for testing
            // console.log(saveSchemaLog);
            // console.log(JSON.stringify(saveSchemaLog));
        }

        return saveSchema;
    }
    return undefined;
}

function mapValue(field: any, value: any): string | boolean {
    const _value = value;
    if (field.type === 'checkbox' || field.type === 'group-checkbox') {
        if (_value === undefined || _value.length === 0) {
            return false;
        }
        return true;
    } else if (field.type === 'date') {
        if (_value === undefined || _value === '' || _value === 0 || _value === '0' || isNaN(_value)) {
            return '';
        }
        return createNonOpinionatedDisplayDate(_value);
    }
    return _value || '';
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function createNonOpinionatedDisplayDate(value: any):string {
    if (isNaN(value)) {
        return '';
    }
    // Moment has functionality to pad out days and months with leading zeroes. Date has not -, so let's stick with it.
    // A browser may not have a norwegian locale, or its locale might have been overridden automatically by a VPN connection so we can't use functions intended for displays.
    // Thus I'm removing locale, and toLocaleLowerCase. Even if I force the format, they might kick in with LTR marks for locales with default RTL
    let date: string = moment(value).locale('nb-NO').format('DD.MM.YYYY');
    // console.log('The date '+ _value + ' was converted to ' + date + ' with a length of ' + date.length + ' for id = ' + field.id);
    if (date.length !== 10) {
        // Logging special cases for dates:
        console.error('The length of the converted date was not 10: xxx ' + date);
        // Assuming LTR marks in the date, try fix it (This will happen if users have opted willingly or automatically for exotic locales like arabic or jewish)
        date = date.replace(/[^0-9.]/g, '');
        console.log('Trying to correct the value by removing LTR marks: ' + date);
    }
    return date;
}

function mapNumericValue(field: any, value: any):number|undefined {
    if (value !== undefined && field.format !== undefined && field.format.type === 'amount' && field.type !== 'generated') {
        const numericArgument = value.replace(/[ ]/g, '') + '';
        if (numericArgument === '') {
            return undefined;
        }
        return +numericArgument;
    } else if (field.type === 'date') {
        if (value === '' || isNaN(+value)) {
            // Cl is setting it to NaN instead of 0, when clicking the 'x'.
            return 0;
        }
        return +value;
    }
    return undefined;
}

function mapDisplayValue(field: any, _value: any):string {
    if (field.type === 'date') {
        if (_value === undefined || _value === '' || _value === 0 || _value === '0') {
            return '';
        }
        moment.locale('nb-NO');
        return moment(_value).format('LLL');
    }
    return ''
}
