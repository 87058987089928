import * as yup from 'yup';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function createYupSchema(schema, config) {
    const { id, validationType, validations = [] } = config;
    if (!yup[validationType]) {
        return schema;
    }
    let validator = yup[validationType]();
    validations.forEach((validation) => {
        const { params, type } = validation;
        if (type === 'matches') {
            params[0] = RegExp(params[0]);
        }
        if (!validator[type]) {
            return;
        }
        validator = validator[type](...params);
    });
    schema[id] = validator;
    return schema;
}
