import React from 'react';
import { connect } from 'react-redux';
import * as OrganisationStore from '../../store/Organisation';
import { ApplicationState } from '../../store';
import Button from '../button';
import { LabelsContext } from '../../utils/labels-context';
import { UserContext } from '../../utils/auth-context';
import Organisation from '../organisation/organisation';
import { NavLink } from 'react-router-dom';
import { ErrorMessage } from '@in/component-library';

import './organisation-overview.scss';

type OrganisationProps = OrganisationStore.OrganisationState & typeof OrganisationStore.actionCreators;

class OrganisationOverview extends React.PureComponent<OrganisationProps> {
    public componentDidMount() {
        this.ensureDataFetched();
    }

    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    private get hasChanges(): boolean {
        return this.props.organisations.some((o) => o.deletedAuthorizationGroupIds.length > 0 || Object.keys(o.addedAuthorizationGroups).length > 0 || Object.keys(o.updatedAuthorizationGroups).length > 0);
    }

    public render() {
        return (
            <React.Fragment>
                <UserContext.Consumer>
                    {(userContext) => (
                        <LabelsContext.Consumer>
                            {(labels) =>
                                userContext !== undefined && userContext.user !== undefined && userContext.user.groups !== undefined && userContext.user.groups.some((g) => g.isAdmin === true) ? (
                                    <div className="organisationOverview">
                                        {this.props.errorMsgs.map((errorMsg) => (
                                            <ErrorMessage className="organisationError" key={errorMsg.id} errorMessage={errorMsg.message}></ErrorMessage>
                                        ))}
                                        <h1>{labels.authorizationGroups}</h1>
                                        <Button onClick={() => this.saveChanges()} disabled={!this.hasChanges} theme={this.hasChanges ? 'green' : 'gray'} type="submit">
                                            {labels.saveChanges}
                                        </Button>
                                        &nbsp;
                                        {userContext.user.isINAdmin && (
                                            <NavLink to={`/organisationDetails/`}>
                                                <Button theme="gray" onClick={() => {}}>
                                                    {labels.add} {labels.organisation}
                                                </Button>
                                            </NavLink>
                                        )}
                                        {this.props.organisations.map((organisation: OrganisationStore.Organisation) => (
                                            <Organisation
                                                key={organisation.id}
                                                editingGroupId={this.props.editingGroup}
                                                onAddAuthorizationGroup={() => this.addAuthorizationGroup(organisation.id)}
                                                onAuthorizationGroupChange={(g) => this.updateGroup(g)}
                                                onAuthorizationGroupEndEdit={() => this.endEditAuthGroup()}
                                                onAuthorizationGroupStartEdit={(authGroupId) => this.startEditAuthGroup(authGroupId)}
                                                onDeleteAuthorizationGroup={(authGroupId) => this.deleteAuthorizationGroup(organisation.id, authGroupId)}
                                                onRestoreAuthorizationGroup={(authGroupId) => this.restoreAuthorizationGroup(organisation.id, authGroupId)}
                                                onRevertAuthorizationGroup={(authGroupId) => this.revertAuthorizationGroup(organisation.id, authGroupId)}
                                                organisation={organisation}
                                            />
                                        ))}
                                    </div>
                                ) : (
                                    <div className="organisationOverview">
                                        <h1>{labels.unauthorized}</h1>
                                    </div>
                                )
                            }
                        </LabelsContext.Consumer>
                    )}
                </UserContext.Consumer>
            </React.Fragment>
        );
    }

    private updateGroup(authGroup: OrganisationStore.AuthorizationGroup) {
        this.props.updateAuthorizationGroup(authGroup);
    }

    private startEditAuthGroup(authorizationGroupId: string) {
        this.props.startEditAuthorizationGroup(authorizationGroupId);
    }

    private endEditAuthGroup() {
        this.props.endEditAuthorizationGroup();
    }

    private ensureDataFetched() {
        this.props.requestOrganisation('');
    }

    private deleteAuthorizationGroup(organisationId: string, authorizationGroupId: string) {
        this.props.deleteAuthorizationGroup(organisationId, authorizationGroupId);
    }

    private revertAuthorizationGroup(organisationID: string, authorizationGroupId: string) {
        this.props.revertAuthorizationGroup(organisationID, authorizationGroupId);
    }

    private restoreAuthorizationGroup(organisationId: string, authorizationGroupId: string) {
        this.props.restoreAuthorizationGroup(organisationId, authorizationGroupId);
    }

    private addAuthorizationGroup(organisationId: string) {
        this.props.addAuthorizationGroup(organisationId);
    }

    private saveChanges() {
        this.props.saveChanges();
    }
}

export default connect(
    (state: ApplicationState) => state.organisation, // Selects which state properties are merged into the component's props
    OrganisationStore.actionCreators // Selects which action creators are merged into the component's props
)(OrganisationOverview as any);
