import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { UserConsumer, GetUserStatus } from './auth-context';
import LoginPage from '../pages/loginpage';


const loggedin = (loggedOnUser):boolean => {
    return loggedOnUser.status === GetUserStatus.LOGGED_IN
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const ProtectedRoute = ({ component: Component, ...rest }) => (
    <UserConsumer>
        {(loggedOnUser ) => (
            <Route
                render={props => 
                    loggedin(loggedOnUser) ? <Component {...props} /> : <LoginPage {...props}/>
                }
                {...rest}
            />
        )}
    </UserConsumer>
);

ProtectedRoute.propTypes = {
    component: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func,
        PropTypes.arrayOf(PropTypes.node)
    ])
};
export default ProtectedRoute;
