import React from 'react';
import OrganisationDetails from '../../components/organisation-details/organisation-details';
import { RouteComponentProps } from 'react-router-dom';

interface RouteParams {
    organisationId: string;
}

type OrganisationDetailsProps = RouteComponentProps<RouteParams>;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
class OrganisationDetailsPage extends React.PureComponent<OrganisationDetailsProps> {
    public render() {
        return <OrganisationDetails {...{ id: this.props.match.params.organisationId }}></OrganisationDetails>;
    }
}

export default OrganisationDetailsPage;
