import { FieldAttributes } from 'formik';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function handleTextFormats(fieldAttributes: any) {
    const format = fieldAttributes.format;
    const name = fieldAttributes.field.name;
    let value = fieldAttributes.form.values[fieldAttributes.field.name];
    const setFieldValue = fieldAttributes.form.setFieldValue;


    if(value !== undefined) {
        if(typeof fieldAttributes.form.values[fieldAttributes.field.name] === "string") {
            const valueTrimmed = fieldAttributes.form.values[fieldAttributes.field.name].trim();
            if(value.length !== valueTrimmed.length) {
                setFieldValue(name, valueTrimmed); // async setter will not finish before later
                fieldAttributes.form.values[fieldAttributes.field.name] = valueTrimmed;
                fieldAttributes.form.validateField(fieldAttributes.field.name);
                value = valueTrimmed;
            }
        }
    }

    if (format !== undefined) {
        // console.log('Format detected > ' + format.type);
    }
    if (format !== undefined) {
        if (format.type === 'accountnumber') {
            if (value !== undefined && value.length > 0) {
                if (/^[0-9]{11}$/.test(value)) {
                    const formattedValue = value
                        .substring(0, 4)
                        .concat('.')
                        .concat(value.substring(4, 6))
                        .concat('.')
                        .concat(value.substring(6));
                    setFieldValue(name, formattedValue);
                    fieldAttributes.form.values[fieldAttributes.field.name]=formattedValue;
                    fieldAttributes.form.validateField(fieldAttributes.field.name);
    
                }
            }
        } else if (format.type === 'amount') {
            if (value !== undefined && value.length > 0) {
                let formattedValue = value.replace(/[ ]/g, '');
                const val2 = +formattedValue.valueOf();

                if (format.max !== undefined && val2 > format.max) {
                    setFieldValue(name, format.max);
                    formattedValue = format.max.toString();
                }
                if (format.min !== undefined && val2 < format.min) {
                    setFieldValue(name, format.min);
                    formattedValue = format.min.toString();
                }
                const numparts = reverseString(formattedValue).match(/.{1,3}/g);
                if (numparts !== null) {
                    const val = numparts.reverse().map(v => {
                        return reverseString(v);
                    });
                    setFieldValue(name, val.join(' '));
                }
            } else {
                setFieldValue(name, '');
            }
        } else if (format.type === 'name') {
            if (value !== undefined && value.length > 0) {
                const formattedValue  = value.charAt(0).toUpperCase() + value.slice(1);
                setFieldValue(name, formattedValue);
            } else {
                setFieldValue(name, '');
            }
        } else if (format.type === 'nace') {
            if ((value !== undefined) && (value.length === 5) && !isNaN(+value)) {
                const formattedValue = value.substring(0,2).concat('.').concat(value.substring(2));
                setFieldValue(name, formattedValue);
                fieldAttributes.form.values[fieldAttributes.field.name]=formattedValue;
                fieldAttributes.form.validateField(fieldAttributes.field.name);
            }
        } else if (format.type === 'percent') {
            // console.log('decimals detected ' + _value);
            if (value !== undefined && value.length > 0) {
                const decimalSeparatorIndex = value.lastIndexOf(','); // Only the last ',' is treated as an actual decimal separator. The others are regarded as slightly funny
                if (decimalSeparatorIndex > -1) {
                    // See what we have
                    let number = value.substring(0, decimalSeparatorIndex);
                    let decimals = value.substring(decimalSeparatorIndex + 1);

                    // console.log("------------------ here be decimals ----------------------");
                    // console.log(`number: >${number}< decimals: >${decimals}<.`);

                    // delete excess decimal separators
                    number = number.replace(/[,]/g, '');
                    number = ''.concat(number).length === 0 ? '0' : number;

                    // delete excess decimals without rounding -Sigurd
                    if (decimals.length > 2) {
                        decimals = decimals.substring(0, 2);
                    }
                    // The rule of two decimals is compulsive (KF)
                    decimals = decimals.padEnd(2, '0');
                    
                    // create a proper view
                    const formattedValue = number.concat(',').concat(decimals);
                    setFieldValue(name, formattedValue);
                } else {
                    setFieldValue(name, value.concat(',').concat('00'));
                }
            }
        } else if (format.type === 'phonenumber') {
            const expression = '^(\\(\\+047\\)|\\(\\+47\\)|\\+47|\\+047|047|47|\\s*)\\s*?(\\d( ?\\d){7})\\s*?$';
            const regexp = new RegExp(expression, 'i');
            const match = regexp.exec(value);
            if (match && match[1] !== undefined) {
                // We now know what the user wants, so let's set the value in a uniform way
                const telephoneNumber = match[2].replace(/ /g,'');
                setFieldValue(name, fieldAttributes.schemavalue.valueprefix + ' ' + telephoneNumber);
            }
        }
    }
}

export function reverseString(str: string): string {
    return str === '' ? '' : reverseString(str.substr(1)) + str.charAt(0);
}

export function pasteHandler(e: any, field: any, fieldAttributes: FieldAttributes<any>) {
    const clipboardData = e.clipboardData || (window as any).clipboardData;
    const pastedData = clipboardData.getData('Text');
    if (field.value.length + pastedData.length >= fieldAttributes.maxlength) {
        e.stopPropagation();
        e.preventDefault();
        // setTextareaError({ Error: true, Reason: '- For liten plass til klipp og lim' });
    } else {
        // setTextareaError({ Error: false});
    }
}

// For handling maxlength (made tab work again)
// Todo: Use a ref to detect if the field has selected text in order to allow a paste into the selected portion of the input string. For now this is only for experimental use :)
export function keyDownHandler(e: any, field: any, fieldAttributes: FieldAttributes<any>) {
    // e.persist();
    // console.log(e);
    if (field.value === undefined || field.value.length < fieldAttributes.maxlength) {
        // setTextareaError({ Error: false});
        return true;
    }
    if (
        ((e.key === 'x' || e.key === 'X') && e.ctrlKey === true) ||
        ((e.key === 'a' || e.key === 'A') && e.ctrlKey === true) ||
        e.key === 'ArrowLeft' ||
        e.key === 'ArrowRight' ||
        e.key === 'ArrowUp' ||
        e.key === 'ArrowDown' ||
        e.key === 'Delete' ||
        e.key === 'Backspace' ||
        e.key === 'Tab'
    ) {
        // setTextareaError({ Error: false});
        return true;
    }
    e.preventDefault(e);
    // setTextareaError({ Error: true, Reason: '' });
    return false;
}

const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
export function getWidth(fieldAttributes: FieldAttributes<any>, overlayAttributes?: any) {

    const view = (overlayAttributes === undefined) ? fieldAttributes : overlayAttributes;

    // Take ems before px
    if (view.maxwidthem !== undefined) {
        const width = !isChrome ? view.maxwidthem * 1.06 + 'em' : view.maxwidthem + 'em';
        return { maxWidth: width, minWidth: width, width: width };
    } else {
        const width = !isChrome ? view.maxwidth * 1.06 + 'px' : view.maxwidth + 'px';
        return { maxWidth: width, minWidth: width, width: width };
    }
}

export function isAmountOrInterrest(fieldAttributes: FieldAttributes<any>) {
    if (fieldAttributes.format !== undefined && (fieldAttributes.format.type === 'amount' || fieldAttributes.format.type === 'percent')) {
        return true;
    }
    return false;
}
