import React, { useState, useContext } from 'react';
import { timer } from 'rxjs';
import cn from 'classnames';
import './message-context.scss';
import { Toast, Notification } from '@in/component-library';
import { v4 as uuidv4 } from 'uuid';

export interface IMessageDispatchAction {
    Action: string;
    Key?: string;
    Message: IMessage;
}

export interface IMessageContext {
    messages: IMessage[];
    addMessage(errorMessage: IMessage): void;
    dispatch(action: IMessageDispatchAction): void;
    handleAPIError(title: string, response: any): void;
}

export interface IMessage {
    key?: string;
    message: string;
    tooltip?: string;
    ttl?: number;
    type: string;
    view?: string;
}

interface IAssociativeFlags {
    [key: string]: boolean;
}

function distinct(_messages: IMessage[]) {
    const flags: IAssociativeFlags = {};
    const distinctMessages: IMessage[] = [];
    _messages.forEach((m) => {
        if (flags[m.message] !== true) {
            distinctMessages.push(m);
            flags[m.message] = true;
        }
    });
    return distinctMessages;
}

enum MessageType {
    Error = 'error',
    Info = 'info',
    Success = 'success',
    Warning = 'warning',
}
interface ShowMessagesProps {
    className?: string;
    key?:string;
    top?:boolean;
    view?: string;
}

// Rules:
// 1. The messages list must at all times be distinct.
// 2. Successes should only be shown for a brief period.
// 3. Errors and warnings will persist on the page.

const ShowMessages = (props: ShowMessagesProps): JSX.Element => {
    const messageContext = useContext(MessageContext);
    const _messages: JSX.Element[] = [];
    const _toastMessage: JSX.Element[] = [];
    if (messageContext !== undefined && messageContext.messages !== undefined) {
        if (messageContext.messages.length >= 0) {
            messageContext.messages.forEach((m) => {
                if ((props.view === undefined && m.view === undefined) || (props.view !== undefined && props.view.length > 0 && props.view === m.view)) {
                    m.type === MessageType.Success
                        ? _toastMessage.push(<Toast key={m.key} className="toast" header={m.message} iconType="success" position="top-center" visible />)
                        : _messages.push(
                              <Notification key={m.key} dismissable type={m.type}>
                                  {m.message}
                              </Notification>
                          );
                }
            });
        }
    }

    const returnMessage: JSX.Element[] = [];
    if (_messages.length > 0)
        returnMessage.push(
            <div key="" className={props!.view || ''}>
                <div className="messageSpacing">{_messages}</div>
            </div>
        );
    if (_toastMessage.length > 0)
        returnMessage.push(
            <div className={cn('successMessageWrapper', { top: props.top })}>
                <div className="successMessage">{_toastMessage}</div>
            </div>
        );

    return (
        <div>
            {returnMessage &&
                returnMessage.map((message, index) => {
                    return { ...message, key: { index } };
                })}
        </div>
    );
};

const initialMessageContextValue: IMessageContext = {
    messages: [],
    dispatch() {
        console.log('dispatch is not initialized');
    },
    addMessage() {
        console.log('addMessage is not initialized');
    },
    handleAPIError() {
        console.log('handleAPIError is not initialized');
    },
};

const MessageContext = React.createContext<IMessageContext>(initialMessageContextValue);
const MessageConsumer = MessageContext.Consumer;

function MessageProviderWrapper(props: { children: React.ReactNode }): JSX.Element {
    const messageContextValueWithDispatch: IMessageContext = {
        messages: [],
        dispatch(action: IMessageDispatchAction) {
            if (action.Action === 'Add') {
                action.Message.key = uuidv4();

                if (action.Message.ttl !== undefined && action.Message.ttl > 0) {
                    timer(action.Message.ttl).subscribe(() => {
                        if (action.Message.key !== undefined) {
                            const oldLength = this.messages.length;
                            const filteredMessages = this.messages.filter((m) => m.key !== action.Message.key);
                            if (oldLength > filteredMessages.length) {
                                this.messages = [...filteredMessages];
                                setMessageContext({ ...messageContext, ...this });
                            }
                        }
                    });
                }

                this.messages.push({ ...action.Message });
                this.messages = distinct(this.messages);
                setMessageContext({ ...messageContext, ...this });
            } else if (action.Action === 'Remove') {
                if (action.Key !== undefined && action.Key !== '') {
                    if (this.messages !== undefined && this.messages.length > 0) {
                        const oldLength = this.messages.length;
                        const filteredMessages = this.messages.filter((m) => m.key !== action.Key);
                        if (oldLength > filteredMessages.length) {
                            this.messages = [...filteredMessages];
                            setMessageContext({ ...messageContext, ...this });
                        }
                    }
                }
            }
        },
        addMessage(errorMessage: IMessage) {
            const errorAction: IMessageDispatchAction = {
                Action: 'Add',
                Message: errorMessage,
            };
            this.dispatch(errorAction);
        },
        handleAPIError(title: string, response: any) {
            console.error(title, response);
            if (response !== undefined) {
                const status = response.status;
                let msg = '';
                switch (status) {
                    case 302:
                        msg = 'Brukeren er ikke autorisert.';
                        window.location.href = '/';
                        break;
                    case 400:
                        msg = 'Forespørselen inneholder ugyldige data.';
                        break;
                    case 401:
                        msg = 'Brukeren er ikke autorisert.';
                        break;
                    case 404:
                        msg = 'Ressursen ble ikke funnet.';
                        break;
                    case 500:
                        msg = 'En uforutsett feil oppstod på tjeneren.';
                        break;
                    default:
                        msg = response !== undefined ? response.data.title : '';
                        break;
                }
                const time = response !== undefined ? new Date(response.headers.date).toLocaleTimeString() : new Date().toLocaleTimeString();
                this.addMessage({ message: `(${time}) ${title}: ${msg}`, type: 'error' });
            }
        },
    };

    const [messageContext, setMessageContext] = useState<IMessageContext>(initialMessageContextValue);

    if (messageContext === initialMessageContextValue) {
        setMessageContext(messageContextValueWithDispatch);
    }
    return <MessageContext.Provider value={messageContext}>{props.children}</MessageContext.Provider>;
}

export { MessageConsumer, MessageContext, MessageProviderWrapper, ShowMessages };
