import React from 'react';
import { UserConsumer, GetUserStatus } from '../../utils/auth-context';
import { Button, ButtonSize } from '@in/component-library';
import './login.scss';
import { NavLink } from 'react-router-dom';

const logInFunc = () => {
    window.location.href = '/api/session/login?ReturnUrl=/';
};

const Login: any = () => {
    const loginReady = (loggedOnUser: any) => {
        return loggedOnUser.status === GetUserStatus.WAITING;
    };

    return (
        <UserConsumer>
            {(loggedOnUser) => (
                <>
                    <div className="login-page">
                        {!loginReady(loggedOnUser) ? (
                            <>
                                <div className="pageLayout">
                                    <div className="visibleLink">
                                        <h1>Vekstgarantier</h1>
                                        <h3>Problemer med løsningen eller noe du lurer på?</h3>
                                        <p>
                                            <span className="loginBold">Har du ikke tilgang eller har problemer med selve påloggingen?</span>
                                            <br />
                                            Kontakt din egen IT-avdeling.
                                        </p>
                                        <p>
                                            <span className="loginBold">Har du andre tekniske problemer?</span>
                                            <br />
                                            Kontakt Innovasjon Norges kundesupport på:{' '}
                                            <a className="visibleLink" rel="noopener noreferrer" target="_blank" href="mailto:kundesupport@innovasjonnorge.no">
                                                kundesupport@innovasjonnorge.no
                                            </a>
                                        </p>
                                        <p>
                                            <span className="loginBold">Har du spørsmål angående pre-kvalifisering, saksbehandling eller utfylling av skjema?</span>
                                            <br />
                                            Kontakt Innovasjon Norges rådgivere på:{' '}
                                            <a className="visibleLink" rel="noopener noreferrer" target="_blank" href="mailto:vekstgaranti@innovasjonnorge.no">
                                                vekstgaranti@innovasjonnorge.no
                                            </a>
                                        </p>
                                    </div>
                                    <div className="front-page-nav">
                                        <NavLink to="/vekstgaranti">
                                            <Button theme="positive" size={ButtonSize.Large} onClick={logInFunc}>
                                                Logg inn med AAD pålogging
                                            </Button>
                                        </NavLink>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </>
            )}
        </UserConsumer>
    );
};

export default Login;
