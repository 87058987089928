import React from 'react';
import * as OrganisationStore from '../../store/Organisation';
import { LabelsContext } from '../../utils/labels-context';
import { NavLink } from 'react-router-dom';
import AuthorizationGroup from '../authorization-group/authorization-group';
import Button from '../button';
import FaIcon from '../fa-icon';
import { UserContext } from '../../utils/auth-context';

type OrganisationProps = {
    organisation: OrganisationStore.Organisation;
    editingGroupId: string | undefined;
    onAuthorizationGroupStartEdit: (authGroupId: string) => void;
    onAuthorizationGroupEndEdit: () => void;
    onAuthorizationGroupChange: (authGroup: OrganisationStore.AuthorizationGroup) => void;
    onRevertAuthorizationGroup: (authGroupId: string) => void;
    onRestoreAuthorizationGroup: (authGroupId: string) => void;
    onDeleteAuthorizationGroup: (authGroupId: string) => void;
    onAddAuthorizationGroup: () => void;
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
class Organisation extends React.PureComponent<OrganisationProps> {
    public render() {
        const organisation = this.props.organisation;

        return (
            <UserContext.Consumer>
                {(userContext) => (
                    <LabelsContext.Consumer>
                        {(labels) => (
                            <div key={organisation.id}>
                                <h2>
                                    {organisation.displayName}
                                    {userContext.user.isINAdmin && (
                                        <NavLink to={`/organisationDetails/${organisation.id}`}>
                                            <div className={'iconButton small-iconButton'}>
                                                <FaIcon name="edit" />
                                            </div>
                                        </NavLink>
                                    )}
                                </h2>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className="header-element" scope="col"></th>
                                            <th className="header-element" scope="col">
                                                {labels.displayName}
                                            </th>
                                            <th className="header-element" scope="col">
                                                {labels.canSubmitApplications}
                                            </th>
                                            <th className="header-element" scope="col">
                                                {labels.isAdmin}
                                            </th>
                                            <th className="header-element" scope="col">
                                                {labels.remoteId}
                                            </th>
                                            <th className="header-element" scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {organisation.authorizationGroups.concat(organisation.addedAuthorizationGroups).map((authGroup: OrganisationStore.AuthorizationGroup, index) => {
                                            authGroup = organisation.updatedAuthorizationGroups[authGroup.id] || authGroup;
                                            const isDeleted = organisation.deletedAuthorizationGroupIds.find((id) => id === authGroup.id) !== undefined;
                                            const isEditing = authGroup.id === this.props.editingGroupId;
                                            const hasChanges = organisation.updatedAuthorizationGroups[authGroup.id] !== undefined;

                                            return (
                                                <AuthorizationGroup
                                                    authGroup={organisation.updatedAuthorizationGroups[authGroup.id] || authGroup}
                                                    hasChanges={hasChanges}
                                                    isDeleted={isDeleted}
                                                    isEditing={isEditing}
                                                    index={index}
                                                    onDelete={() => this.props.onDeleteAuthorizationGroup(authGroup.id)}
                                                    onEndEdit={() => this.props.onAuthorizationGroupEndEdit()}
                                                    onRestore={() => this.props.onRestoreAuthorizationGroup(authGroup.id)}
                                                    onRevert={() => this.props.onRevertAuthorizationGroup(authGroup.id)}
                                                    onStartEdit={() => this.props.onAuthorizationGroupStartEdit(authGroup.id)}
                                                    onChange={(group) => this.props.onAuthorizationGroupChange(group)}
                                                    key={authGroup.id}
                                                ></AuthorizationGroup>
                                            );
                                        })}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan={6}>
                                                <Button className="iconButton" onClick={() => this.props.onAddAuthorizationGroup()}>
                                                    <FaIcon name="plus-circle" regular={false} />
                                                    {labels.add}
                                                </Button>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        )}
                    </LabelsContext.Consumer>
                )}
            </UserContext.Consumer>
        );
    }
}

export default Organisation;
