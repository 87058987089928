import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './fa-icon.scss';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const FaIcon = props => (
    <span className={cn('fontawesome', props.className)}>
        {props.regular ? (
            <FontAwesomeIcon
                onClick={e => props.onClick && props.onClick(e)}
                icon={['far', props.name]}
                size={props.size}
            />
        ) : (
            <FontAwesomeIcon
                onClick={e => props.onClick && props.onClick(e)}
                icon={props.name}
                size={props.size}
            />
        )}
    </span>
);

FaIcon.propTypes = {
    name: PropTypes.string.isRequired,
    regular: PropTypes.bool,
    size: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func
};

export default FaIcon;
