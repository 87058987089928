import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './icon.scss';

const sizes = {
    tiny: 'tiny',
    small: 'small',
    medium: 'medium',
    large: 'large',
    xlarge: 'xlarge'
};

const availableIcons = {
    accountNumber: require('../../assets/icons/piggybank2.svg'),
    arrowRight: require('../../assets/icons/arrow-right.svg'),
    checkbox: require('../../assets/icons/checkmark.svg'),
    checkmark: require('../../assets/icons/checkmark-outline.svg'),
    cross: require('../../assets/icons/button-close.svg'),
    date: require('../../assets/icons/calendar.svg'),
    fileUpload: require('../../assets/icons/paperclip.svg'),
    gardsnummer: require('../../assets/icons/barn.svg'),
    loader: require('../../assets/icons/loading.svg'),
    plus: require('../../assets/icons/add.svg'),
    user: require('../../assets/icons/user.svg')
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const Icon = ({ className, name, size }) =>
    !name ? null : (
        <span
            className={cn('icon', `icon--${name}`, `icon--${size}`, className)}
            style={{ backgroundImage: `url(${availableIcons[name]})` }}
        />
    );

Icon.propTypes = {
    className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    name: PropTypes.oneOf(Object.keys(availableIcons)),
    size: PropTypes.oneOf(Object.keys(sizes).map(key => sizes[key]))
};

Icon.defaultProps = {
    size: sizes.small
};

Icon.sizes = sizes;

export default Icon;
