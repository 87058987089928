// Todo: This should be encrypted to prevent browser add-ins with 'good intentions' sniffing data.
// Preferably do it with crypto-js, but there must be a fallback to plain text in case the browser doesn't support it.
// IE7 for instance lack object types and functions for crypto-js.

import * as React from 'react';
import { IApplicationContext } from './application-context';
import moment from 'moment';
import { FormikValues } from 'formik';

// Keep the current applicationdata in this object to prepare for a 401 where we might not have all the needed data to proceed through a reauthentication.
export interface IApplicationValues {
    ApplicationContext: IApplicationContext; // ApplicationContext with applicationId and fileCount:{}
    SchemaValues: any; // Payload from last save
    LocationHref: string; // Page of app
    PageId: number; // Page of schema
    TimeStamp: number; // Start of session expose
}

/* eslint-disable @typescript-eslint/no-empty-function */
const currentApplicationValues: IApplicationValues = {
    ApplicationContext: { applicationId: '', fileCount: {}, autoSavedValues: {}, dispatch(){} },
    LocationHref: '',
    PageId: 0,
    SchemaValues: {},
    TimeStamp: 0,
};

// If unauthorized you must log in again
export function reAuthenticate(loginTimeStamp?: number): void {
    // The last login should be of a certain age to prevent possible infinite reauthentication loops from bugged services
    // (When a JSON error response arrives, it will allways have a status. If not, we assume the session has expired.)
    if (loginTimeStamp !== undefined && loginTimeStamp < moment.now() + 5000) {
        console.log('Infinite login loop detected');
        return;
    }
    window.location.href = '/api/session/login?ReturnUrl=' + currentApplicationValues.LocationHref;
}

export function getValuesSavedByReauthentication(): IApplicationValues | undefined {
    // console.log('Checking if there is something to load from session');
    const applicationValues = loadApplicationValuesFromSession();
    return applicationValues;
}

// Save all data needed to reopen the application after a forced re-login
// Rules:   - You can only save this value once. Then it must expire.
//          - After it has been saved, and a 401 occurs, it will be loaded again using formik initial values
export function saveApplicationValuesIntoSession(): void {
    if (currentApplicationValues.TimeStamp === 0) {
        currentApplicationValues.TimeStamp = Date.now();
        localStorage.setItem('ApplicationValues', JSON.stringify(currentApplicationValues));
    }
}

export function loadApplicationValuesFromSession(): IApplicationValues | undefined {
    const applicationSaveValues = localStorage.getItem('ApplicationValues');
    if (applicationSaveValues === null) {
        return undefined;
    }
    return JSON.parse(applicationSaveValues);
}

// updateApplicationValues - Keep the latest values needed to return after a reauthenticate
// Whenever one of these gets updated in the solution, call updateApplicationValues
export function updateApplicationValues(schemaValues?: FormikValues, locationHref?: string, pageId?: number, timeStamp?: number, applicationContext?: IApplicationContext): void {
    if (applicationContext !== undefined) {
        currentApplicationValues.ApplicationContext = applicationContext;
    }
    if (schemaValues !== undefined) {
        currentApplicationValues.SchemaValues = { ...schemaValues }; // Object with property per value
    }
    if (locationHref !== undefined) {
        currentApplicationValues.LocationHref = locationHref;
    }
    if (pageId !== undefined) {
        currentApplicationValues.PageId = pageId;
    }
    if (timeStamp !== undefined) {
        currentApplicationValues.TimeStamp = timeStamp;
    }
}

// Wrap this around the root from which you want to monitor locations.
export function LocationMonitorWrapper(props: { children: React.ReactNode }): JSX.Element {
    updateApplicationValues(undefined, window.location.href);
    return <div>{props.children}</div>;
}
