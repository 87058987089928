import React, { FC } from 'react';
import { updateApplicationValues } from './reauthenticate';

export interface IDispatchAction {
    Command: string;
    Value: string;
    Values: Record<string, unknown>;
    FieldName: string;
}

export interface IApplicationContext {
    applicationId: string | undefined;
    fileCount: any;
    autoSavedValues: any;
    dispatch(action: IDispatchAction): void;
}

const InitialApplicationContext: IApplicationContext = {
    applicationId: undefined,
    autoSavedValues: {},
    fileCount: {},
    dispatch(action: IDispatchAction) {
        if (action.Command === 'applicationId') {
            this.applicationId = action.Value;
        } else if (action.Command === 'fileCount') {
            this.fileCount[action.FieldName] = action.Value;
        } else if (action.Command === 'autoSavedValues') {
            this.autoSavedValues = action.Values;
        }
        updateApplicationValues(undefined, undefined, undefined, undefined, this);
    },
};

const ApplicationContext = React.createContext<IApplicationContext>(InitialApplicationContext);

const ApplicationContextWrapper: FC<any> = (props) => {
    return <ApplicationContext.Provider value={InitialApplicationContext}>{props.children}</ApplicationContext.Provider>;
};

export { ApplicationContextWrapper, ApplicationContext };
