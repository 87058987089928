import React, { useState, useEffect, VFC } from 'react';
import { FieldAttributes } from 'formik';
import './input-date-cl.scss';

import { addDays } from 'date-fns';
import { DateInput } from '@in/component-library';
import moment from 'moment';

const InputDateCl: VFC<FieldAttributes<any>> = (fieldAttributes) => {
    // Create state for date
    const [date, setDate] = useState<number | string>(isNaN(fieldAttributes.form.values[fieldAttributes.field.name]) ? 0 : (fieldAttributes.form.values[fieldAttributes.field.name] as number));

    // Update the date state if the date values have changed
    useEffect(() => {
        setDate(isNaN(fieldAttributes.form.values[fieldAttributes.field.name]) ? 0 : (fieldAttributes.form.values[fieldAttributes.field.name] as number));
    }, [fieldAttributes.field.name, fieldAttributes.form.values]);

    const handleChange = (date: any) => {
        // Set formik value in a format that react datepicker can understand. That is unix epoch time * 1000 if you don't care about milliseconds
        if (date !== undefined && date !== null) {
            if (date !== fieldAttributes.form.values[fieldAttributes.field.name]) {
                fieldAttributes.form.values[fieldAttributes.field.name] = moment(date).unix() * 1000;
                fieldAttributes.form.touched[fieldAttributes.field.name] = true;
                setDate(moment(date).unix() * 1000);
                fieldAttributes.form.validateField(fieldAttributes.field.name);
            }
        } else {
            fieldAttributes.form.values[fieldAttributes.field.name] = '';
            fieldAttributes.form.touched[fieldAttributes.field.name] = true;
            setDate('');
            fieldAttributes.form.validateField(fieldAttributes.field.name);
        }
    };

    // Get max - and min date
    function getDate(dateType: string, readonly: any) {
        // Simulate an unselectable date() by setting max = min
        if (readonly !== undefined && readonly === true) {
            return new Date();
        }
        if (fieldAttributes.schemavalue.customvalidations !== undefined) {
            for (let index = 0; index < fieldAttributes.schemavalue.customvalidations.length; index++) {
                const validation = fieldAttributes.schemavalue.customvalidations[index];
                if (validation.type === dateType) {
                    if (!isNaN(validation.params[0])) {
                        return addDays(Date.now(), validation.params[0]);
                    }
                }
            }
        }
        return undefined;
    }

    function showError() {
        if (fieldAttributes.form.touched[fieldAttributes.field.name] && fieldAttributes.form.errors[fieldAttributes.field.name]) {
            return fieldAttributes.form.errors[fieldAttributes.field.name];
        }
        return undefined;
    }

    function createDate(date: any): Date | undefined {
        if (date === null || date === undefined || date === '') return undefined;
        return new Date(date);
    }

    return fieldAttributes.id === undefined ? (
        <span>missing id for {fieldAttributes.field.name}</span>
    ) : (
        <div className="OverrideCLLastChildBorder">
            <DateInput
                id={fieldAttributes.id}
                name={fieldAttributes.id}
                label={fieldAttributes.label}
                errorMsg={showError()}
                onChange={handleChange}
                maxDate={getDate('maxdate', fieldAttributes.readonly)}
                minDate={getDate('mindate', fieldAttributes.readonly)}
                value={createDate(date)}
                showMonthDropdown={true}
                showYearDropdown={true}
            />
        </div>
    );
}

export default InputDateCl;
