import { handleTextFormats } from '../input-text-cl/format';
import React, { useEffect, VFC } from 'react';
import { useField, FieldAttributes } from 'formik';
import './input-text-cl.scss';
import { InputField } from '@in/component-library';
import { timer } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';

const InputTextCl: VFC<FieldAttributes<any>> = (fieldAttributes) => {
    const [field] = useField(fieldAttributes.field.name);

    useEffect(() => {
        if (fieldAttributes.contactinformation[fieldAttributes.field.name] !== undefined && fieldAttributes.form.values[fieldAttributes.field.name] !== fieldAttributes.contactinformation[fieldAttributes.field.name]) {
            fieldAttributes.form.setFieldValue(fieldAttributes.field.name, fieldAttributes.contactinformation[fieldAttributes.field.name]);
            timer(1000).subscribe(() => {
                fieldAttributes.form.validateField(fieldAttributes.field.name);
            });
        }
    }, [fieldAttributes.contactinformation, fieldAttributes.field.name, fieldAttributes.form]); // Add extra similar form objects here when needed for other initializations

    const handleChange = (e: any) => {
        field.onChange(e);
    };

    const handleBlur = (e: any) => {
        e.persist();
        if (fieldAttributes.callback !== undefined) {
            fieldAttributes.callback(e, fieldAttributes.form.setFieldValue);
        }
        handleTextFormats(fieldAttributes);
        fieldAttributes.form.validateField(fieldAttributes.field.name);
        field.onBlur(e);
    };

    function showError() {
        if (fieldAttributes.form.touched[fieldAttributes.field.name] === true && fieldAttributes.form.errors[fieldAttributes.field.name] !== undefined) {
            return fieldAttributes.form.errors[fieldAttributes.field.name];
        }
        return undefined;
    }

    function ensureValueIsSet() {
        if (fieldAttributes.form.values[fieldAttributes.field.name] === undefined) {
            return '';
        }
        return fieldAttributes.form.values[fieldAttributes.field.name];
    }

    return (
        <div className={classNames('OverrideCLLastChildBorder', { hidden: fieldAttributes?.schemavalue?.hidden === true })}>
            {!fieldAttributes?.schemavalue?.hidden ? (
                <InputField
                    autoComplete={uuidv4() as string}
                    suffix={fieldAttributes.suffix}
                    key={fieldAttributes.field.name}
                    name={fieldAttributes.field.name}
                    label={fieldAttributes.label}
                    placeholder={fieldAttributes.placeholder}
                    helpText={fieldAttributes.schemavalue.help}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={fieldAttributes.readonly === true ? true : false}
                    value={ensureValueIsSet()}
                    errorMsg={showError()}
                    hidden={fieldAttributes.field.hidden}
                    textAlignRight={
                        fieldAttributes.format !== undefined && (fieldAttributes.format.type === 'amount' || fieldAttributes.format.type === 'percent' || fieldAttributes.format.type === 'accountnumber' || fieldAttributes.suffix === 'mnd')
                            ? true
                            : false
                    }
                />
            ) : (
                fieldAttributes?.schemavalue?.hidden && <input type="hidden" name={fieldAttributes.field.name} disabled={true} hidden={true}></input>
            )}
        </div>
    );
};

export default InputTextCl;
