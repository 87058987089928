import React from 'react';

export const labels = {
    en: {
        add: 'Add',
        address: 'Adress',
        advisor: 'Advisor',
        adminTools: 'Admin tools',
        allDocuments: 'All documents',
        applicationsEvaluated: 'Evaluated applications',
        applicationsEvaluation: 'Applications for evaluation',
        applicationsReturned: 'Returned applications',
        assignAdvisor: 'Start evaluation',
        authorizationGroups: 'Authorization Groups',
        back: 'Back',
        canSubmitApplications: 'Can submit applications',
        chars: 'characters',
        city: 'City',
        Delete: 'Delete',
        displayName: 'Display name',
        downloadDocuments: 'Download all documents (for archiving)',
        edit: 'Edit',
        evaluateApplication: 'Give an evaluation of application',
        formValidationError: 'Correct form errors',
        goToEvaluation: 'Go to evaluation',
        innovationNorway: 'Innovation Norway',
        isAdmin: 'Administrator',
        kv: "The municiaplity's evaluation",
        listApplications: 'List applications',
        loggedInAs: 'Logged in as',
        login: 'Login',
        loginIN: 'Logg in IN',
        loginMunicipalityBankId: 'Log in municipality with BankID',
        loginMunicipalityBankIdMobil: 'Log in municipality with BankID mobile',
        logout: 'Logout',
        logoutMS: ' ',
        maxCharacterLengthExceeded: 'Can not exceed',
        manageUsers: 'Manage Users',
        mustHaveMinimum: 'Must have a value of at least',
        mustHaveValue: 'Must have a value',
        myProfile: 'My profile',
        no: 'No',
        noApplication: 'No application found',
        notApplicable: 'Not applicable',
        numbers: 'numbers',
        ofMax: 'of max',
        optional: '(optional)',
        OrganisationNumber: 'Organisation Number',
        organisation: 'organisation',
        organisations: 'organisations',
        Organisation: 'Organisation',
        Organisations: 'Organisations',
        phoneNumber: 'Phone number',
        postCode: 'Post Code',
        preview: 'Control and send',
        remoteId: 'Remote ID',
        remove: 'Remove',
        restore: 'Restore',
        return: 'Return',
        required: 'Required',
        save: 'Save',
        saveChanges: 'Save changes',
        selectedApplication: 'Selected application',
        send: 'Send',
        showAll: 'Show all',
        submit: 'Submit',
        submitReturn: 'Return to applicant',
        submitted: 'Submitted',
        tenantId: 'Tenant Id',
        uploadFiles: 'Choose files',
        uploadFilesHere: 'Drag and drop file(s) here',
        unauthorized: 'You are not authorized to view this resource',
        yes: 'Yes'
    },
    nb: {
        add: 'Legg til',
        address: 'Adresse',
        adminTools: 'Administratorverktøy',
        advisor: 'Rådgiver',
        allDocuments: 'Alle dokumenter',
        applicationsEvaluated: 'Vurderte søknader',
        applicationsEvaluation: 'Søknader til vurdering',
        applicationsStartedEval: 'Påbegynte søknadsvurderinger',
        applicationsReturned: 'Returnerte søknader',
        assignAdvisor: 'Start vurdering',
        authorizationGroups: 'Autorisasjonsgrupper',
        back: 'Tilbake',
        city: 'Poststed',
        Delete: 'Slett',
        displayName: 'Visningsnavn',
        canSubmitApplications: 'Kan sende søknad',
        chars: 'tegn',
        downloadDocuments: 'Last ned alle dokumenter (til arkivering)',
        edit: 'Rediger',
        evaluateApplication: 'Gi en vurdering av søknaden',
        formValidationError: 'Inneholder feil',
        goToEvaluation: 'Gå til vurdering',
        headerForINUser: 'Kommunens Vurdering for Innovasjon Norge',
        help: 'Hjelp',
        innovationNorwayUserWelcomeText:
            'Velkommen til Kommunens Vurdering av søknader innenfor tradisjonell bruksutbygging',
        innovationNorway: 'Innovasjon Norge',
        isAdmin: 'Administrator',
        kv: 'Kommunens vurdering',
        listApplications: 'Søknadsoversikt',
        loggedInAs: 'Logget inn som',
        login: 'Logg inn',
        loginIN: 'Logg inn som Innovasjon Norge-bruker',
        loginMunicipalityBankId: 'Logg inn som kommunebruker med BankID',
        loginMunicipalityBankIdMobil: 'Logg inn som kommunebruker med BankID på mobil',
        logout: 'Logg ut',
        logoutMS: ' ',
        maxCharacterLengthExceeded: 'Kan maks være',
        maxFileSizeExceeded: 'Maks filstørrelse 10mb',
        manageUsers: 'Brukeroppsett',
        municipalityUserWelcomeText:
            'Velkommen til kommunesiden for vurdering av tradisjonell bruksutbygging søknader på vegne av Innovasjon Norge.',
        mustHaveMinimum: 'Må ha minimum',
        mustHaveValue: 'Må fylles ut',
        myProfile: 'Min profil',
        no: 'Nei',
        noApplication: 'Ingen søknad funnet',
        notApplicable: 'Ikke aktuelt',
        numbers: 'tall',
        ofMax: 'av maks',
        optional: '(valgfritt)',
        OrganisationNumber: 'Organisasjonsnummer',
        organisation: 'organisasjon',
        organisations: 'organisasjoner',
        Organisation: 'Organisasjon',
        Organisations: 'Organisasjoner',
        phoneNumber: 'Telefonnummer',
        postCode: 'Postnummer',
        preview: 'Kontroller og send',
        remoteId: 'Ekstern ID',
        remove: 'Fjern',
        restore: 'Angre endringer',
        return: 'Returner',
        required: 'Må fylles ut',
        save: 'Lagre',
        saveChanges: 'Lagre endringer',
        selectedApplication: 'Valgt søknad',
        send: 'Send inn',
        showAll: 'Vis alle',
        submit: 'Send inn',
        submitReturn: 'Returner til søker',
        submitted: 'Innsendt',
        tenantId: 'Tenant Id',
        uploadFiles: 'Velg fil(er)',
        uploadFilesHere: 'Dra og slipp fil(er) her',
        unauthorized: 'Du er ikke autorisert for tilgang til denne ressursen',
        yes: 'Ja'
    },
    nn: {
        add: 'Legg til',
        address: 'Adresse',
        adminTools: 'Administratorverktøy',
        advisor: 'Rådgjevar',
        allDocuments: 'Alle dokumenter',
        applicationsEvaluated: 'Vurderte søknader',
        applicationsEvaluation: 'Søknader til vurdering',
        applicationsReturned: 'Returnerte søknader',
        applicationsStartedEval: 'Påbegynte søknadsvurderinger',
        assignAdvisor: 'Start vurdering',
        authorizationGroups: 'Autoriseringsgrupper',
        back: 'Tilbake',
        canSubmitApplications: 'Kan sende søknad',
        chars: 'tegn',
        city: 'Poststed',
        Delete: 'Slett',
        displayName: 'Visningsnavn',
        downloadDocuments: 'Last ned alle dokumenter (til arkivering)',
        evaluateApplication: 'Gi ein vurdering av søknaden',
        edit: 'Rediger',
        formValidationError: 'Skjemaet inneholder feil.',
        goToEvaluation: 'Gå til vurdering',
        headerForINUser: '',
        help: '',
        innovationNorwayUserWelcomeText: '',
        innovationNorway: 'Innovasjon Noreg',
        isAdmin: 'Administrator',
        kv: 'Kommunens vurdering',
        listApplications: 'Søknadsoversikt',
        loggedInAs: 'Logget inn som',
        login: 'Logg inn',
        loginIN: 'Logg inn som Innovasjon Norge-brukar',
        loginMunicipalityBankId: 'Logg inn som kommunebrukar med BankID',
        loginMunicipalityBankIdMobil: 'Logg inn som kommunebrukar med BankID på mobil',
        logout: 'Logg ut',
        logoutMS: ' ',
        maxCharacterLengthExceeded: '',
        maxFileSizeExceeded: '',
        manageUsers: 'Brukeroppsett',
        municipalityUserWelcomeText: '',
        mustHaveMinimum: 'Må ha minimum',
        mustHaveValue: 'Må fylles ut',
        myProfile: 'Min profil',
        no: 'Nei',
        noApplication: 'Ingen søknad funnet',
        notApplicable: 'Ikkje aktuelt',
        numbers: 'tall',
        ofMax: 'av maks',
        optional: '(valgfritt)',
        OrganisationNumber: 'Organisasjonsnummer',
        organisation: 'organisasjon',
        organisations: 'organisasjoner',
        Organisation: 'Organisasjon',
        Organisations: 'Organisasjoner',
        phoneNumber: 'Telefonnummer',
        postCode: 'Postnummer',
        preview: 'Kontroller og send',
        remoteId: 'Ekstern ID',
        remove: 'Fjern',
        restore: 'Angre endringer',
        return: 'Returner åt søkjar',
        required: 'Må fylles ut',
        save: 'Lagre',
        saveChanges: 'Lagre endringer',
        selectedApplication: 'Valgt søknad',
        send: 'Send inn',
        showAll: 'Vis alle',
        submit: 'Send inn',
        submitReturn: 'Returner til søker',
        submitted: 'Innsendt',
        tenantId: 'Tenant Id',
        uploadFiles: 'Velg fil(er)',
        uploadFilesHere: 'Dra og slipp fil(er) her',
        unauthorized: 'Du er ikke autorisert for tilgang til denne ressursen',
        yes: 'Ja'
    }
};

export const LabelsContext = React.createContext(labels.nb);
