import { ILoggedOnUser } from '../../utils/auth-context';
import { ISchema } from './input-schema';

export interface ContactInformation {
    Sub_Intermediary_Contact_Name: string;
    Sub_Intermediary_Contact_Name_Family: string;
    Sub_Intermediary_Contact_eMail: string;
    A_3_5_Sub_Intermediary_Name: string;
    A_3_6_Sub_Intermediary_Address: string;
    A_3_7_Sub_Intermediary_Postal_Code: string;
    A_3_8_Sub_Intermediary_Place: string;
    Sub_Intermediary_Region: string;
    Sub_Intermediary_Organisation_Number: string;
}

export function GetContactInformation(schema: ISchema, userContext: ILoggedOnUser): ContactInformation | undefined {
    // console.log(JSON.stringify(userContext));
    if (userContext !== undefined && userContext.user !== undefined && userContext.user.displayName !== null) {

        const names: RegExpMatchArray | null = userContext.user.displayName.match(/^(.*?)(\S+)$/);

        // if ((schema.pages[0].values as IValue[]).filter(f => f.id === 'Sub_Intermediary_Contact_Name').length === 0) {
        //     return undefined; // This is not the expected first page, so let's just return without any errors in case of testing things locally
        // }

        // I had to set the formik enableReinitialize to false since having it to true in rare cases would
        // reset the form. I know what I did but it's not obvious why it should reinitialize.
        // But now we can't update the schema with setSchema anymore, so I'll just fill an object and send values
        // down with the field props and use the setFieldValue() instead.

        const contactInformation: ContactInformation = {
            Sub_Intermediary_Contact_Name: '',
            Sub_Intermediary_Contact_Name_Family: '',
            Sub_Intermediary_Contact_eMail: '',
            A_3_5_Sub_Intermediary_Name: '',
            A_3_6_Sub_Intermediary_Address: '',
            A_3_7_Sub_Intermediary_Postal_Code: '',
            A_3_8_Sub_Intermediary_Place: '',
            Sub_Intermediary_Region: '',
            Sub_Intermediary_Organisation_Number: '',
            ...userContext.user.metaData
        };
        if (names !== null && names.length > 2) {
            contactInformation.Sub_Intermediary_Contact_Name = names[1].trim();
            contactInformation.Sub_Intermediary_Contact_Name_Family = names[2].trim();
        } else {
            contactInformation.Sub_Intermediary_Contact_Name = userContext.user.displayName;
        }
        contactInformation.Sub_Intermediary_Contact_eMail = userContext.user.emailAddress.trim();
        if (userContext.user.organisation !== undefined) {
            contactInformation.A_3_5_Sub_Intermediary_Name = userContext.user.organisation.displayName.trim();
        }
        contactInformation.A_3_6_Sub_Intermediary_Address = userContext.user.organisation.address;
        contactInformation.A_3_7_Sub_Intermediary_Postal_Code = userContext.user.organisation.postCode;
        contactInformation.A_3_8_Sub_Intermediary_Place = userContext.user.organisation.city;
        contactInformation.Sub_Intermediary_Organisation_Number = userContext.user.organisation.organisationNumber;
        return contactInformation;
    }
    return undefined;
}
