import React from 'react';

const Logout: any = () => {
    window.location.href = 'api/session/logout';
    return <div></div>;
};

const LogoutPage: any = () => <Logout />;

export default LogoutPage;
