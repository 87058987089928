import React, { useState, VFC } from 'react';
import { useField, FieldAttributes } from 'formik';
import './input-radio-cl.scss';
import { IOption } from '../../input-schema/input-schema';
import { Radiobutton } from '@in/component-library';

const InputRadioCl: VFC<FieldAttributes<any>> = (fieldAttributes) => {
    const [field] = useField(fieldAttributes.field.name);

    function handleSelect(e: any) {
        // Set both for a lifecycle reason
        fieldAttributes.form.values[fieldAttributes.field.name] = e.target.value;
        fieldAttributes.form.setFieldValue(field.name, e.target.value);
    }
    function getHelpTextRaw() {
        if (fieldAttributes === undefined || fieldAttributes.schemavalue === undefined || fieldAttributes.schemavalue.help === undefined) {
            return undefined;
        }
        return fieldAttributes.schemavalue.help;
    }

    interface Option {
        value: string;
        text: string;
        selected: boolean;
    }

    const initialOptions: Option[] = [];
    fieldAttributes.options.forEach((_option:IOption) => {
        initialOptions.push({
            text: _option.text,
            value: _option.value,
            selected: fieldAttributes.form.values[fieldAttributes.field.name] === _option.value
        }
    )});
    const [options, setOptions] = useState(initialOptions);

    function showError() {
        if (fieldAttributes.form.touched[fieldAttributes.field.name] && fieldAttributes.form.errors[fieldAttributes.field.name]) {
            return fieldAttributes.form.errors[fieldAttributes.field.name];
        }
        return undefined;
    }

    return (
    <div>
        <div className="oneline radioButtonWrapper">
            <div>
                <Radiobutton
                    name={fieldAttributes.field.name}
                    label={fieldAttributes.label}
                    helpTextRich={getHelpTextRaw()}
                    options={options}
                    errorMsg={showError()}
                    onChange={(e:any): void => {
                        setOptions(
                            options.map((option, index) => ({
                                ...option,
                                selected: e.target.checked && e.target.id.indexOf('-' + index) > -1,
                            }))
                        );
                        handleSelect(e);
                    }}
                />
            </div>
        </div>
    </div>
    );
}

export default InputRadioCl;
