import React, { useState, useEffect, VFC } from 'react';
import { FieldAttributes } from 'formik';
import './input-generated-cl.scss';
import { addThousandSeparator } from '../../../utils/numbers';
import { DisplayMode } from '../../input-schema/input-schema';

const InputGeneratedCl: VFC<FieldAttributes<any>> = (fieldAttributes) => {    
    const error = fieldAttributes.form.errors[fieldAttributes.field.name];
    const touch = fieldAttributes.form.touched[fieldAttributes.field.name];

    const handleChange = (e: any) => {
        fieldAttributes.form.handleChange(e);
        fieldAttributes.form.validateField(fieldAttributes.field.name);
    };

    function keyDownHandler(e: any) {
        if (e.keyCode === 13) {
            e.preventDefault();
            return false;
        }
    }

    interface IFieldInformation {
        information: string;
        displayValue: string;
    }

    const [result, setResult] = useState<IFieldInformation>({ displayValue: '', information: '' });

    useEffect(() => {
        calculate(fieldAttributes.schemavalue);
    }, [fieldAttributes.form.values[fieldAttributes.field.name]]);

    // let description = undefined;
    function calculate(schemavalue: any) {
        if (schemavalue.calculation.type === 'subsidiaryeffect') {
            const type = fieldAttributes.form.values['A_2_1_1_Final_Recipient_Transaction_Type'];
            let A_2_5_Maturity_Months_Number;
            if(type + '' === "Driftskreditt") {
                A_2_5_Maturity_Months_Number = getFieldValue('A_2_5_Maturity_Months_DK');
                // console.log('A_2_5_Maturity_Months_Number Dk ' + A_2_5_Maturity_Months_Number);
            } else {
                A_2_5_Maturity_Months_Number = getFieldValue('A_2_5_Maturity_Months');
                // console.log('A_2_5_Maturity_Months_Number Ned ' + A_2_5_Maturity_Months_Number);
            }

            const A_2_4_Principal_Amount_Number = getFieldValue('A_2_4_Principal_Amount');
            // console.log('A_2_4_Principal_Amount_Number ' + A_2_4_Principal_Amount_Number);

            if (A_2_5_Maturity_Months_Number !== undefined && A_2_4_Principal_Amount_Number !== undefined) {
                // console.log('Calculating effect');
                // Sigurd: Garanti_andel	A_3_2_Sub_Operation_Guarantee_Rate	"0,75" (Konstant = 75,00%)
                const A_3_2_Sub_Operation_Guarantee_Rate = fieldAttributes.schemavalue.calculation.a_3_2_sub_operation_guarantee_rate;
                // Sigurd: Garanti_Ansvar_IN	Guarantee_Rate_IN	"0,5" (konstant, halvparten av garantiansvaret hviler på IN og halvparten på EIF)
                const Guarantee_Rate_IN = fieldAttributes.schemavalue.calculation.guarantee_rate_in;

                // Formula suggestion
                // Subsideeffekt[Løpetid(  <rund.ned>(  (A_2_5_Maturity_Months / 12)  +0,49) ) ]
                // *        (A_2_4_Principal_Amount * A_3_2_Sub_Operation_Guarantee_Rate * Guarantee_Rate_IN)

                // Added the -1 to the formula due to arrays starting on 0
                const effectOfSubsidyFactor = schemavalue.calculation.effects[Math.floor(A_2_5_Maturity_Months_Number / 12 + 0.49)].effect;
                // console.log('effectOfSubsidyFactor ' + effectOfSubsidyFactor)
                const effectOfSubsidy = effectOfSubsidyFactor * A_2_4_Principal_Amount_Number * A_3_2_Sub_Operation_Guarantee_Rate * Guarantee_Rate_IN;
                // console.log('effectOfSubsidy ' + effectOfSubsidy)
                const effectOfSubsidyDisplayValue = addThousandSeparator(effectOfSubsidy.toFixed(0));
                // console.log('effectOfSubsidyDisplayValue ' + effectOfSubsidyDisplayValue)


                if (effectOfSubsidyDisplayValue !== undefined) {
                    fieldAttributes.form.values[fieldAttributes.field.name] = effectOfSubsidyDisplayValue;
                    const descriptionTmp: IFieldInformation = { information: fieldAttributes.schemavalue.calculation.description, displayValue: effectOfSubsidyDisplayValue };
                    descriptionTmp.information = (descriptionTmp.information as string).replace(/XXXX/, effectOfSubsidyDisplayValue);
                    setResult(descriptionTmp);
                } else {
                    setResult({ information: '', displayValue: 'Ikke tilgjengelig' });
                }
            }
        } else if (schemavalue.calculation.type === 'arrangementfee') {
            // Etableringsgebyr 1%

            const A_2_4_Principal_Amount_Number = getFieldValue('A_2_4_Principal_Amount');
            // console.log('A_2_4_Principal_Amount_Number: ' + A_2_4_Principal_Amount_Number);

            // Sigurd: Garanti_andel	A_3_2_Sub_Operation_Guarantee_Rate	"0,75" (Konstant = 75,00%)
            const A_3_2_Sub_Operation_Guarantee_Rate = fieldAttributes.schemavalue.calculation.a_3_2_sub_operation_guarantee_rate;
            // console.log('A_3_2_Sub_Operation_Guarantee_Rate: ' + A_3_2_Sub_Operation_Guarantee_Rate);

            // Etableringsgebyr	Arrangement_Fee_Rate	"0,01" (konstant = 1,00%)
            const Arrangement_Fee_Rate = fieldAttributes.schemavalue.calculation.arrangement_fee_rate;
            // console.log('Arrangement_Fee_Rate: ' + Arrangement_Fee_Rate);
            if (A_2_4_Principal_Amount_Number !== undefined) {
                const Sub_Operation_Fee = A_2_4_Principal_Amount_Number * A_3_2_Sub_Operation_Guarantee_Rate * Arrangement_Fee_Rate;
                const Sub_Operation_Fee_DisplayValue = addThousandSeparator(Sub_Operation_Fee.toFixed(0));
                if (Sub_Operation_Fee_DisplayValue !== undefined) {
                    fieldAttributes.form.values[fieldAttributes.field.name] = Sub_Operation_Fee_DisplayValue;
                    const descriptionTmp: IFieldInformation = { information: fieldAttributes.schemavalue.calculation.description, displayValue: Sub_Operation_Fee_DisplayValue };
                    descriptionTmp.information = (descriptionTmp.information as string).replace(/XXXX/, Sub_Operation_Fee_DisplayValue);
                    setResult(descriptionTmp);
                } else {
                    setResult({ information: '', displayValue: 'Ikke tilgjengelig' });
                }
            } else {
                setResult({ information: '', displayValue: 'Ikke tilgjengelig' });
            }
        } else if (schemavalue.calculation.type === 'guaranteefee') {
            // Provision 0.75%

            const A_2_4_Principal_Amount_Number = getFieldValue('A_2_4_Principal_Amount');
            // console.log('A_2_4_Principal_Amount_Number: ' + A_2_4_Principal_Amount_Number);

            // Sigurd: Garanti_andel	A_3_2_Sub_Operation_Guarantee_Rate	"0,75" (Konstant = 75,00%)
            const A_3_2_Sub_Operation_Guarantee_Rate = fieldAttributes.schemavalue.calculation.a_3_2_sub_operation_guarantee_rate;
            // console.log('A_3_2_Sub_Operation_Guarantee_Rate: ' + A_3_2_Sub_Operation_Guarantee_Rate);

            // Provisjon_fra_långiver_til_IN	Sub_Operation_Guarantee_Fee_Rate	"0,0075" (Konstant = 0,75%)
            const Sub_Operation_Guarantee_Fee_Rate = fieldAttributes.schemavalue.calculation.sub_operation_guarantee_fee_rate;
            // console.log('Sub_Operation_Guarantee_Fee_Rate: ' + Sub_Operation_Guarantee_Fee_Rate);

            if (A_2_4_Principal_Amount_Number !== undefined) {
                const Sub_Operation_Guarantee = A_2_4_Principal_Amount_Number * A_3_2_Sub_Operation_Guarantee_Rate * Sub_Operation_Guarantee_Fee_Rate;
                const Sub_Operation_Guarantee_DisplayValue = addThousandSeparator(Sub_Operation_Guarantee.toFixed(0));
                if (Sub_Operation_Guarantee_DisplayValue !== undefined) {
                    fieldAttributes.form.values[fieldAttributes.field.name] = Sub_Operation_Guarantee_DisplayValue;
                    const descriptionTmp: IFieldInformation = { information: fieldAttributes.schemavalue.calculation.description, displayValue: Sub_Operation_Guarantee_DisplayValue };
                    descriptionTmp.information = (descriptionTmp.information as string).replace(/XXXX/, Sub_Operation_Guarantee_DisplayValue);
                    setResult(descriptionTmp);
                } else {
                    setResult({ information: '', displayValue: 'Ikke tilgjengelig' });
                }
            } else {
                setResult({ information: '', displayValue: 'Ikke tilgjengelig' });
            }
        } else if (schemavalue.calculation.type === 'sum') {
            let sumOfConstants = 0;
            for (const constant of schemavalue.calculation.constants || []) {
                sumOfConstants += constant;
            }
            let sumOfVariables = 0;
            for (const variable of schemavalue.calculation.variables || []) {
                const fieldValue = getFieldValue(variable);
                if (fieldValue !== undefined) {
                    sumOfVariables = fieldValue;
                }
            }

            const displayValue = `${addThousandSeparator((sumOfConstants + sumOfVariables).toFixed(0))}`;
            fieldAttributes.form.values[fieldAttributes.field.name] = displayValue;
            const descriptionTmp: IFieldInformation = { information: fieldAttributes.schemavalue.calculation.description, displayValue: displayValue };
            descriptionTmp.information = (descriptionTmp.information as string).replace(/XXXX/, displayValue);
            setResult(descriptionTmp);
        } else {
            setResult({ information: '', displayValue: 'Ikke implementert' });
        }
    }

    function getFieldValue(key: string) {
        if (fieldAttributes.form.values[key] === undefined) {
            return undefined;
        }
        const amount = fieldAttributes.form.values[key].replace(/[ ]/g, '');

        let amountNumber = 0;
        if (!isNaN(amount)) {
            amountNumber = +amount;
        }
        if (amountNumber === 0) {
            // warning, principal amount = 0
            console.warn('principal amount = 0');
        }
        return amountNumber;
    }

    function isDisplayMode(mode:DisplayMode) {
        if(fieldAttributes.displaymode === mode) {
            return true
        }
        return false;
    }
    

    if (fieldAttributes.schemavalue.hidden === true) {
        return <input type="hidden" id={fieldAttributes.field.name} name={fieldAttributes.field.name} value={result.displayValue} onChange={handleChange} />;
    } else {
        return (
            <div className="fieldWrapper generated">
                    
                    {isDisplayMode(DisplayMode.Value) &&
                    <>
                        <div className="form-label-generated">
                            <label htmlFor={fieldAttributes.field.name} className={fieldAttributes.labelClassName}>
                                {fieldAttributes.label}
                            </label>
                        </div>
                        {/* value */}
                        <div className="showEffectOfSubsidy">{result.displayValue}</div>

                        {/* form value */}
                        <input onKeyDown={keyDownHandler} autoComplete="chrome" type="hidden" id={fieldAttributes.field.name} name={fieldAttributes.field.name} value={result.displayValue} onChange={handleChange} />{' '}
                        
                    </>
                    }
                    {isDisplayMode(DisplayMode.Information) &&
                    <>
                            {/* information */}
                            { result.information && <div className="descriptionWrapper">{result.information}</div> }

                            {/* error */}
                            <div className="errorWrapper">{touch && error && <label>{error}</label>}</div>
                    </>
                    }
            </div>
        );
    }

}

export default InputGeneratedCl;
