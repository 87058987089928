import React, { useContext } from 'react';
import { Button, ButtonSize } from '@in/component-library';
import './prequalification.scss';
import { NavLink } from 'react-router-dom';
import { resetSchemaValues } from '../../components/input-schema/input-schema';
import { IApplicationContext, IDispatchAction, ApplicationContext } from '../../components/input-schema/application-context';

const PreQualification:any = () => {
    function ResetApplicationId(props: { children: React.ReactNode }) {
        // Reset applicationId just in case the application is abandoned before closure, so a new id can be made
        // We don't want relations to files belonging to the previous abandoned app to mystically appear again.
        const action: IDispatchAction = {
            Command: 'applicationId',
            FieldName: '',
            Value: '',
            Values: {},
        };
        const applicationContextValue: IApplicationContext = useContext(ApplicationContext);
        applicationContextValue.dispatch(action);
        return <div>{props.children}</div>;
    }

    function removeSessionValues() {
        localStorage.removeItem('ApplicationValues');
        resetSchemaValues();
    }

    return (
        <div className="pageLayoutRegularPage">
            <div>
                <h1>Prekvalifisering</h1>
                <p className="pre ingress">Før innmelding bør låntaker vurderes opp mot disse kriteriene, for å vurdere om bedriften kvalifiserer til vekstgaranti.</p>
                <p className="pre normal">Dersom det kan svares «Nei» til begge spørsmål om bedriften nedenfor, kvalifiserer bedriften til vekstgaranti og kan registreres i innmeldingsskjema. </p>

                <div className="pre infoBoxWrapper">
                    <p className="infoBoxHeader">1. Er bedriften/konsernet i økonomiske vanskeligheter?</p>
                    <p>
                        <i>En bedrift/konsern som er i økonomiske vanskeligheter kvalifiserer ikke til vekstgaranti</i>
                    </p>
                    <p className="noBottomMargin">En bedrift/konsern er å definere som i økonomiske vanskeligheter hvis:</p>
                    <ul className="infoBoxList">
                        <li>
                            Bedriften/konsernet har tapt mer enn halvparten av den tegnede kapitalen (innskutt egenkapital), inkl. overkurs, som følge av akkumulerte tap. Tegnet kapital omfatter ikke ansvarlige lån eller andre gjeldsinstrumenter.
                        </li>
                        <li>
                            Denne «testen» må bestås både av bedriften og konsernet som bedriften eventuelt er en del av. I sistnevnte tilfelle skal konsoliderte regnskaper legges til grunn. Foreligger ikke konsoliderte tall, må konsernselskapenes
                            tall summeres manuelt, dog med adgang til å foreta elimineringer. Det er uproblematisk om enkeltselskaper i konsernet (dog ikke søker) isolert sett ikke består «testen».
                            <br />
                            NB! Dette gjelder kun bedrifter/konsern som er eldre enn 3 år. Yngre bedrifter/konsern kan således ha tapt mer enn 50% av tegnet kapital og likevel være kvalifisert for vekst-garantiordningen.
                            <br />
                            Bedriften/konsernet er insolvent. Kravet til solvens gjelder uavhengig av alder på bedrift/konsern.
                        </li>
                        <li>Bedriften er i mislighold.</li>
                        <li>Bedriften har betalingsanmerkninger av alvorlig grad.</li>
                    </ul>
                    <p>
                        <span className="infoBoxBold">Informasjonsgrunnlag:</span> Bisnode eller andre registre for regnskap og betalingsanmerkninger, samt långivers egen informasjon om eventuelle engasjement og mislighold. Dette gjelder også
                        eksisterende engasjement bedriften har med Innovasjon Norge.
                    </p>
                </div>

                <div className="pre infoBoxWrapper">
                    <p className="infoBoxHeader">2. Er bedriften utenfor grensene for SMB?</p>
                    <p>
                        <i>En bedrift som er utenfor grensene for SMB kvalifiserer ikke til vekstgaranti</i>
                    </p>
                    <p className="noBottomMargin">En bedrift er å definere som innenfor grensene for SMB hvis:</p>
                    <ul className="infoBoxList">
                        <li>Bedriften har mindre enn 250 ansatte og mindre eller lik EUR 50 millioner i omsetning, eller</li>
                        <li>Bedriften har mindre eller lik EUR 43 millioner i balanse.</li>
                    </ul>

                    <p>Dersom bedriften inngår i et konsern er det konserntallene som skal være innenfor grenseverdiene.</p>
                    <p>
                        <span className="infoBoxBold">Informasjonsgrunnlag:</span> Regnskap og konsernstruktur fra Brønnøysundregistrene eller Bisnode (eller andre relevante databaser for slik informasjon).
                    </p>
                </div>
            </div>
            <div>
                <div className="pre buttonWrapper">
                    <NavLink to="/vekstgaranti">
                        <ResetApplicationId>
                            <Button theme="positive" size={ButtonSize.Large}  onClick={removeSessionValues}>
                                Opprett ny søknad
                            </Button>
                        </ResetApplicationId>
                    </NavLink>
                </div>
            </div>
        </div>
    );
};

export default PreQualification;
