import React, { useState, useContext, VFC } from 'react';
import { FieldAttributes } from 'formik';
import './input-dropzone-cl.scss';
import Axios2 from 'axios';
import { AxiosRequestConfig } from 'axios';
import { ApplicationContext } from '../../input-schema/application-context';
import { MessageContext } from '../../../utils/message-context/message-context';
import { updateApplicationValues, saveApplicationValuesIntoSession, reAuthenticate } from '../../input-schema/reauthenticate';
import { FileUpload, CallbackResponse, FileStatus } from '@in/component-library';

export interface Attachment {
    contentType: string;
    uploadName: string;
    name: string;
    uploadTime: Date;
    length: number;
    downloadEndpoint: string;
}

// The id is proprietary and forced by the component, so we need another id for storing the attachmentId
export declare type FileItemExtended = {
    id: string;
    file?: File;
    fileSize: number;
    fileName: string;
    status: FileStatus;
    fileType: string;
    message?: string;
    fieldName?: string;
    totalNumberOfFiles?: number;
    attachmentId: string;
    uploadingfiles?(uploading: boolean): void;
};

const InputDropZoneCl: VFC<FieldAttributes<any>> = (fieldAttributes) => {
    const [dropzoneDataCl, setDropzoneDataCl] = useState<FileItemExtended[]>();
    const [dropzoneDataClValidate, setDropzoneDataClValidate] = useState<FileItemExtended[]>();
    const [uploadingBusy, setUploadingBusy] = useState<boolean>(false);
    const [errorUploadingFiles, setErrorUploadingFiles] = useState<boolean>(false);

    // Get the applicationId from ApplicationContext
    const applicationContextValue = useContext(ApplicationContext);
    const messageContext = useContext(MessageContext);
    const maxFileSizeBytes = 10485760;
    const fileSizeExceededText = 'Filen er for stor';
    const source = Axios2.CancelToken.source();

    // 1. Get any stored files for this field, works ok
    React.useEffect(() => {
        getStoredFilesCl();

        return () => {
            source.cancel();
        };
    }, []);

    React.useEffect(() => {
        let isClosed = false;
        if (dropzoneDataCl !== undefined && !isClosed) {
            setDropzoneDataClValidate([...dropzoneDataCl]);
        }

        return () => {
            isClosed = true;
        };
    }, [dropzoneDataCl]);

    // 2. For any new data, validate, and signal end of loading
    React.useEffect(() => {
        if (uploadingBusy === false) {
            PrepareForValidation();
            fieldAttributes.form.validateField(fieldAttributes.field.name);
        }
        fieldAttributes.uploadingfiles(uploadingBusy);
    }, [dropzoneDataClValidate, errorUploadingFiles, uploadingBusy]);

    // Check for previously stored files.
    function getStoredFilesCl() {
        const tag = fieldAttributes.field.name;
        if (applicationContextValue.applicationId !== undefined) {
            type Headers = { [key: string]: string };
            const defaultHeaders: Headers = {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            };
            const config: AxiosRequestConfig = {
                headers: defaultHeaders,
                cancelToken: source.token,
            };

            const files2: FileItemExtended[] = [];
            return Axios2.get('api/Attachment/ListApplicationAttachments/'.concat(applicationContextValue.applicationId), config).then(
                (response) => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response !== undefined && response.data !== undefined && response.data.length > 0) {
                            // filter on tag
                            const filesForThisField = response.data.filter((f: { tag: string; id: string; name: string; fileSize: number; contentType: string }) => {
                                return f.tag === tag;
                            });

                            let maxFilesForThisField = filesForThisField;

                            // filter on filelength
                            if (maxFilesForThisField.length > fieldAttributes.maxSize) {
                                maxFilesForThisField = maxFilesForThisField.slice(0, fieldAttributes.maxSize);
                            }

                            // create the files structure
                            maxFilesForThisField.forEach((f: { tag: string; id: string; name: string; fileSize: number; contentType: string }) => {
                                files2.push({
                                    fileName: f.name,
                                    fileSize: f.fileSize,
                                    fileType: '', // ?
                                    id: f.id,
                                    status: FileStatus.Ready,
                                    fieldName: f.tag,
                                    attachmentId: f.id,
                                });
                            });

                            // Assume there are no other files here
                            const fileAsArray: FileItemExtended[] = files2;
                            setDropzoneDataCl(fileAsArray);
                        } else {
                            setDropzoneDataCl([]);
                        }
                    } else {
                        setDropzoneDataCl([]);
                    }
                },
                (error) => {
                    messageContext.handleAPIError('Feil ved utlisting av filer', error.response);
                    console.error(error);
                    if (error && error.response) {
                        if (error.response.status === 401 || error.response.status === 405) {
                            // todo: Maybe save values on current page
                            console.error('Detected code 401 Unauthorized for post submitapplication, reautenticating...');
                            updateApplicationValues(undefined, undefined, undefined, undefined, applicationContextValue);
                            saveApplicationValuesIntoSession();
                            reAuthenticate();
                        }
                    }
                }
            );
        }
    }

    function PrepareForValidation() {
        if (dropzoneDataClValidate !== undefined && dropzoneDataClValidate.length !== undefined && dropzoneDataClValidate.length >= 0) {
            fieldAttributes.form.values[fieldAttributes.field.name] = JSON.stringify({
                type: 'files',
                value: dropzoneDataClValidate.length,
                maxFiles: fieldAttributes.maxfiles,
                minFiles: fieldAttributes.minfiles,
                name: fieldAttributes.field.name,
                files: dropzoneDataClValidate,
                errorUploadingFiles: errorUploadingFiles,
            });
        } else {
            fieldAttributes.form.values[fieldAttributes.field.name] = JSON.stringify({
                type: 'files',
                value: 0,
                maxFiles: fieldAttributes.maxfiles,
                minFiles: fieldAttributes.minfiles,
                name: fieldAttributes.field.name,
                files: [],
                errorUploadingFiles: false,
            });
        }
    }

    // Delete start ----------------------
    function fileDeleteHandler(): (attachmentId: string) => Promise<CallbackResponse> {
        return async (attachmentId: string): Promise<CallbackResponse> => {
            return await deleteFile(attachmentId);
        };
    }

    async function deleteFile(attachmentid: string): Promise<CallbackResponse> {
        try {
            if (applicationContextValue.applicationId !== undefined) {
                return removeFile(applicationContextValue.applicationId, attachmentid);
            } else {
                return { message: 'Unknown applicationID', status: false };
            }
        } catch (e) {
            return { message: 'Technical error', status: false };
        }
    }

    async function removeFile(applicationId: string, attachmentId: string): Promise<CallbackResponse> {
        type Headers = { [key: string]: string };
        const defaultHeaders: Headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
        const config: AxiosRequestConfig = {
            headers: defaultHeaders,
            cancelToken: source.token,
        };
        if (attachmentId !== undefined) {
            return Axios2.delete(`api/Attachment/${applicationId}/${attachmentId}`, config).then(
                (response: any) => {
                    if (response.status >= 200 && response.status < 300) {
                        removeFileFromStateCl(attachmentId);

                        return { message: 'file deleted', status: true };
                    } else {
                        messageContext.handleAPIError('Feil ved sletting av fil', response);
                        return { message: 'error deleting file', status: false };
                    }
                },
                (error: any) => {
                    messageContext.handleAPIError('Feil ved sletting av fil', error.response);
                    console.error(error);
                    if (error && error.response) {
                        if (error.response.status === 401 || error.response.status === 405) {
                            updateApplicationValues(undefined, undefined, undefined, undefined, applicationContextValue);
                            saveApplicationValuesIntoSession();
                            reAuthenticate();
                        }
                    }
                    return { message: 'error deleting file.', status: false };
                }
            );
        } else {
            // The file was not uploaded, so simply remove it from state using the key
            removeFileFromStateCl(attachmentId);
            return { message: 'error deleting file..', status: false };
        }
    }

    function removeFileFromStateCl(attachmentId: string) {
        if (dropzoneDataClValidate !== undefined) {
            if (dropzoneDataClValidate.length > 0) {
                const filteredFiles = dropzoneDataClValidate.filter((f) => {
                    return f.attachmentId !== attachmentId;
                });
                if (filteredFiles.length === dropzoneDataClValidate.length - 1) {
                    setDropzoneDataClValidate(filteredFiles);
                    fieldAttributes.form.touched[fieldAttributes.field.name] = true;
                }
            }
        }
    }
    // Delete end --------------------------

    // Upload start ------------------------
    function fileUploadHandler(formId: string, maxFileSizeBytes: number, fileSizeExceededText: string): (fileItem: FileItemExtended) => Promise<CallbackResponse> {
        return async (fileItem: FileItemExtended): Promise<CallbackResponse> => {
            if (fileItem.fileSize > maxFileSizeBytes) return { message: fileSizeExceededText, status: false };
            if (dropzoneDataClValidate && dropzoneDataClValidate.filter((f) => f.id === fileItem.id).length > 0) return { message: '', newId: '', status: true };
            return await uploadFile(formId, fileItem);
        };
    }

    // This callback pattern creates a problem with updating the state when selecting more than one file :/
    // It will call back single files instead of every file once. That calls for too fast updating of the state, so I
    // need to collect them in a regular array first. Then save the state.
    const catchFilesFast: FileItemExtended[] = [];

    async function uploadFile(formId: string, fileItem: FileItemExtended): Promise<CallbackResponse> {
        try {
            const form = new FormData();
            form.append('fieldName', fileItem.fieldName as string);
            if (fileItem.file !== undefined) {
                form.append('Files', fileItem.file);
            } else {
                console.error('#########  File not sendt: ' + fileItem.fileName);
            }
            if (applicationContextValue.applicationId !== undefined) {
                form.append('ApplicationId', applicationContextValue.applicationId);
            }
            if (formId !== undefined) {
                form.append('Tag', formId);
            }

            type Headers = { [key: string]: string };
            const defaultHeaders: Headers = {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            };
            const config: AxiosRequestConfig = {
                headers: defaultHeaders,
                cancelToken: source.token,
            };

            return await Axios2.post('api/Attachment', form, config).then(
                (response: any) => {
                    if (response.data !== undefined && response.data.length === 1) {
                        if (fileItem !== undefined) {
                            fileItem.attachmentId = response.data[0]; // Extend with the actual attachmentid.
                            catchFilesFast.push(fileItem);
                            if (dropzoneDataClValidate !== undefined) {
                                setDropzoneDataClValidate([...dropzoneDataClValidate, ...catchFilesFast]);
                            }
                        }
                        return { message: 'dulicate', newId: response.data[0], status: true };
                    } else {
                        return { message: 'data was missing from service', status: false };
                    }
                },
                (error: any) => {
                    console.error(JSON.stringify(error));
                    return { message: 'Error uploading', status: false };
                }
            );
        } catch (e) {
            return { message: (e as any).toString(), status: false };
        }
    }

    function showError() {
        if (fieldAttributes.form.touched[fieldAttributes.field.name] && fieldAttributes.form.errors[fieldAttributes.field.name]) {
            return fieldAttributes.form.errors[fieldAttributes.field.name];
        }
        return undefined;
    }

    function uploadingFilesBusyCallback(uploadingFile: boolean) {
        setUploadingBusy(uploadingFile);
    }

    return (
        <>
            <FileUpload
                name={fieldAttributes.field.name}
                label={fieldAttributes.label}
                errorMsg={showError()}
                files={dropzoneDataCl}
                fileTypes="application/pdf, text/plain, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.oasis.opendocument.text, application/vnd.oasis.opendocument.spreadsheet"
                uploadCallback={fileUploadHandler(fieldAttributes.field.name, maxFileSizeBytes, fileSizeExceededText)} // fieldAttributes.field.name = tag
                deletionCallback={fileDeleteHandler()}
                requirementsText={fieldAttributes.texts.info}
                uploadingFilesBusyCallback={uploadingFilesBusyCallback}
                errorUploadingFilesCallback={setErrorUploadingFiles}
            />
        </>
    );
};

export default InputDropZoneCl;
