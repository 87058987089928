import { AppThunkAction } from ".";
import { Action, Reducer } from 'redux';
import { Guid } from 'guid-typescript';
import * as _ from 'lodash';

export interface IdCollection<T> {
    [id:string]: T
}

// State definition
export interface AuthorizationGroup {
    id: string;
    displayName: string;
    remoteId: string;
    organisationId: string;
    isApplicantGroup: boolean;
    isAdmin: boolean;
}

export interface Organisation {
    id: string;
    displayName: string;
    authorizationGroups: AuthorizationGroup[],
    updatedAuthorizationGroups: IdCollection<AuthorizationGroup>,
    addedAuthorizationGroups: AuthorizationGroup[],
    deletedAuthorizationGroupIds: string[]
}

export interface ErrorMessage {
    id: string;
    message: string;
}

export interface OrganisationState {
    isLoading: boolean;
    isSaving: boolean;
    isLoaded: boolean;
    organisations: Organisation[];
    editingGroup: string | undefined;
    errorMsgs: ErrorMessage[];
}

// Actions

interface RequestOrganisationAction {
    type: "REQUEST_ORGANISATION_ACTION",
    organisationId: string
}

interface RecieveOrganisationAction {
    type: "RECIEVE_ORGANISATION_ACTION",
    organisations: Organisation[]
}

interface StartEditAuthorizationGroup {
    type: "START_EDIT_AUTHORIZATION_GROUP",
    authorizationGroupId: string
}

interface EndEditAuthorizationGroup {
    type: "END_EDIT_AUTHORIZATION_GROUP"
}

interface UpdateAuthorizationGroup {
    type: "UPDATE_AUTHORIZATION_GROUP",
    updatedAuthorizationGroup: AuthorizationGroup
}

interface AddAuthorizationGroup {
    type: "ADD_AUTHORIZATION_GROUP",
    organisationId: string
}

interface DeleteAuthorizationGroup {
    type: "DELETE_AUTHORIZATION_GROUP",
    organisationId: string,
    authorizationGroupId: string
}

interface RevertAuthorizationGroup {
    type: "REVERT_AUTHORIZATION_GROUP",
    organisationId: string, 
    authorizationGroupId: string,
}

interface RestoreAuthorizationGroup {
    type: "RESTORE_AUTHORIZATIONGROUP",
    organisationId: string,
    authorizationGroupId: string
}

interface SaveChangesAction {
    type: "SAVE_CHANGES"
}

interface AuthorizationGroupUpdateSavedAction {
    type: "AUTHORIZATION_GROUP_UPDATE_SAVED",
    authorizationGroupId: string,
    organisationId: string
}

interface AuthorizationGroupDeleteSavedAction {
    type: "AUTHORIZATION_GROUP_DELETE_SAVED",
    authorizationGroupId: string,
    organisationId: string
}

interface AuthorizationGroupAddSavedAction {    
    type: "AUTHORIZATION_GROUP_ADD_SAVED",
    authorizationGroupTempId: string,
    authorizationGroupNewId: string,
    organisationId: string
}

interface ShowErrorMsgAction {
    type: "SHOW_ERROR_MSG",
    errorMsg: string
}

interface CloseErrorMsgAction {
    type: "CLOSE_ERROR_MSG",
    errorMsgId: string
}

interface InvalidateDataAction {
    type: "INVALIDATE_DATA"
}

const CheckHttpOk = (operation: string, res: Response) : Response  => {
    if (!res.ok) {
        throw new Error(`${operation} failed with error: ${res.statusText}`);
    }
    return res;
}

type KnownAction = RequestOrganisationAction | RecieveOrganisationAction | StartEditAuthorizationGroup
                | EndEditAuthorizationGroup | SaveChangesAction
                | UpdateAuthorizationGroup | AddAuthorizationGroup | DeleteAuthorizationGroup
                | RevertAuthorizationGroup | RestoreAuthorizationGroup
                | AuthorizationGroupUpdateSavedAction | AuthorizationGroupDeleteSavedAction | AuthorizationGroupAddSavedAction
                | ShowErrorMsgAction | CloseErrorMsgAction | InvalidateDataAction
                ;

export const actionCreators = {
    requestOrganisation: (organisationId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.organisation && !appState.organisation.isLoaded && !appState.organisation.isLoading) {
            fetch(`api/authorizationgroup`)
                .then(response => response.json() as Promise<Organisation[]>)
                .then(organisations => {
                    dispatch({ type: "RECIEVE_ORGANISATION_ACTION", organisations })
                });
            dispatch({ type: "REQUEST_ORGANISATION_ACTION", organisationId });
        }
    },

    saveChanges: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.organisation && appState.organisation.isLoaded)
        {
            for (const organisation of appState.organisation.organisations) {
                for(const group of _.flatMap(organisation.addedAuthorizationGroups)) {
                    fetch( "api/authorizationgroup", { method: "POST", body: JSON.stringify(group), headers: { "content-type": "application/json" } })
                    .then(res => CheckHttpOk(`Creating authorization group ${ group.displayName }`, res))
                    .then(res=> res.text())
                    .then(id => id.substring(1, id.length - 1))
                    .then(authorizationGroupNewId => dispatch({ type: "AUTHORIZATION_GROUP_ADD_SAVED", authorizationGroupTempId: group.id, authorizationGroupNewId, organisationId: group.organisationId}))
                    .catch(error => dispatch({ type: "SHOW_ERROR_MSG", errorMsg: error.message}));
                }
                for (const groupId of organisation.deletedAuthorizationGroupIds) {
                    fetch( `api/authorizationgroup/${groupId}`, { method: "DELETE" })
                    .then(res => CheckHttpOk(`Deleting authorizationg group with ID ${ groupId }`, res))
                    .then(() => dispatch({ type: "AUTHORIZATION_GROUP_DELETE_SAVED", authorizationGroupId: groupId, organisationId: organisation.id }))
                    .catch(error => dispatch({ type: "SHOW_ERROR_MSG", errorMsg: error.message}));
                }
                for (const group of _.values(organisation.updatedAuthorizationGroups)) {
                    fetch( `api/authorizationgroup/${ group.id }`, { method: "PUT", body: JSON.stringify(group), headers: { "content-type": "application/json" } })
                    .then(res => CheckHttpOk(`Updating authorization group ${ group.displayName }`, res))
                    .then(() => dispatch({ type: "AUTHORIZATION_GROUP_UPDATE_SAVED", authorizationGroupId: group.id, organisationId: group.organisationId}))
                    .catch(error => dispatch({ type: "SHOW_ERROR_MSG", errorMsg: error.message}));
                }
            }
            dispatch({ type: "SAVE_CHANGES"});
        }
    },

    startEditAuthorizationGroup: (authorizationGroupId: string): AppThunkAction<KnownAction> => (dispatch) => dispatch({ type: "START_EDIT_AUTHORIZATION_GROUP", authorizationGroupId }),
    updateAuthorizationGroup: (updatedAuthorizationGroup: AuthorizationGroup): AppThunkAction<KnownAction> => dispatch => dispatch({ type: "UPDATE_AUTHORIZATION_GROUP", updatedAuthorizationGroup }),
    endEditAuthorizationGroup: (): AppThunkAction<KnownAction> => dispatch => dispatch({ type: "END_EDIT_AUTHORIZATION_GROUP"}),
    deleteAuthorizationGroup: (organisationId: string, authorizationGroupId: string): AppThunkAction<KnownAction> => (dispatch) => dispatch({ type: "DELETE_AUTHORIZATION_GROUP", organisationId, authorizationGroupId}),
    addAuthorizationGroup: (organisationId: string): AppThunkAction<KnownAction> => (dispatch) => dispatch({ type: "ADD_AUTHORIZATION_GROUP", organisationId }),
    revertAuthorizationGroup: (organisationId: string, authorizationGroupId: string): AppThunkAction<KnownAction> => (dispatch) => dispatch({ type: "REVERT_AUTHORIZATION_GROUP", organisationId, authorizationGroupId}),
    restoreAuthorizationGroup: (organisationId: string, authorizationGroupId: string): AppThunkAction<KnownAction> => (dispatch) => dispatch({ type: "RESTORE_AUTHORIZATIONGROUP", organisationId, authorizationGroupId }),
    closeErrorMesassge: (errorMsgId: string): AppThunkAction<KnownAction> => (dispatch) => dispatch({ type: "CLOSE_ERROR_MSG", errorMsgId }),
    invalidateData: (): AppThunkAction<KnownAction> => dispatch => dispatch({ type: "INVALIDATE_DATA" }),
}

const initialState: OrganisationState = {
    isLoading: false,
    isSaving: false,
    isLoaded: false,
    organisations: [],
    editingGroup: undefined,
    errorMsgs: [],
};

export const reducer: Reducer<OrganisationState> =  (state: OrganisationState | undefined, incomingAction: Action): OrganisationState => {
    if (state === undefined) {
        return initialState;
    }

    const action = incomingAction as KnownAction;
    switch(action.type) {
        case "REQUEST_ORGANISATION_ACTION":
            return {
                ...state, 
                isLoading: true,
                isLoaded: false,
            };
        case "RECIEVE_ORGANISATION_ACTION":
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                organisations: action.organisations.map(o => ({
                    ...o,
                    addedAuthorizationGroups: [],
                    deletedAuthorizationGroupIds: [],
                    updatedAuthorizationGroups: {}
                })),
            }
        case "START_EDIT_AUTHORIZATION_GROUP":
            return {
                ...state,
                editingGroup: action.authorizationGroupId
            }
        case "END_EDIT_AUTHORIZATION_GROUP":
            return {
                ...state,
                editingGroup: undefined
            }
        case "UPDATE_AUTHORIZATION_GROUP":
            const organisation = state.organisations.find(o => o.id === action.updatedAuthorizationGroup.organisationId);
            if (organisation !== undefined)
            {
                if (organisation.addedAuthorizationGroups.find(g => g.id === action.updatedAuthorizationGroup.id) !== undefined) {
                    return updateOrganisation(
                            state,
                            action.updatedAuthorizationGroup.organisationId,
                            o => ({
                                ...o,
                                addedAuthorizationGroups: [
                                    ...o.addedAuthorizationGroups.filter(ag => ag.id !== action.updatedAuthorizationGroup.id),
                                    { ...action.updatedAuthorizationGroup }
                                ]
                            })
                            )
                } else {
                    return updateOrganisation(
                        state,
                        action.updatedAuthorizationGroup.organisationId,
                        o => ({
                            ...o,
                            updatedAuthorizationGroups: {
                                ...o.updatedAuthorizationGroups,
                                [action.updatedAuthorizationGroup.id] : action.updatedAuthorizationGroup
                            }
                        }))
                }
            }
            return state;
        case "ADD_AUTHORIZATION_GROUP":
            {
                return updateOrganisation(
                    state,
                    action.organisationId,
                    o => ({
                        ...o,
                        addedAuthorizationGroups: [
                            ...o.addedAuthorizationGroups,
                            { 
                                id: Guid.create().toString(), 
                                displayName: "New", 
                                isApplicantGroup: true, 
                                isAdmin: false,
                                organisationId: action.organisationId,
                                remoteId: ""
                            }
                        ]
                    })
                    );
            }
        case "DELETE_AUTHORIZATION_GROUP":
            {
                return updateOrganisation(
                    state,
                    action.organisationId,
                    o => ({
                        ...o,
                        addedAuthorizationGroups: [
                            ...o.addedAuthorizationGroups.filter(g => g.id !== action.authorizationGroupId)
                        ],
                        deletedAuthorizationGroupIds: o.addedAuthorizationGroups.find(g => g.id === action.authorizationGroupId) ?
                        [
                            ...o.deletedAuthorizationGroupIds
                        ] : [
                            ...o.deletedAuthorizationGroupIds.filter(id => id !== action.authorizationGroupId),
                            action.authorizationGroupId
                        ]
                    })
                );                
            }
        case "RESTORE_AUTHORIZATIONGROUP":
            return updateOrganisation(
                state,
                action.organisationId,
                o => ({
                    ...o,
                    deletedAuthorizationGroupIds: [
                        ...o.deletedAuthorizationGroupIds.filter(id => id !== action.authorizationGroupId)
                    ]
                })
            );
        case "REVERT_AUTHORIZATION_GROUP":
            return {
                    ...updateOrganisation(
                    state,
                    action.organisationId,
                    o => ({
                        ...o,
                        updatedAuthorizationGroups: RemoveFromCollection(o.updatedAuthorizationGroups, (id) => id === action.authorizationGroupId),
                        addedAuthorizationGroups: [
                            ...o.addedAuthorizationGroups.map(g => g.id === action.authorizationGroupId ? {
                                id: g.id,
                                displayName: "New", 
                                isApplicantGroup: true, 
                                isAdmin: false,
                                organisationId: action.organisationId,
                                remoteId: ""                                
                            } : {
                                ...g
                            })

                        ]
                    })
                ),
                editingGroup: undefined
            };
        case "AUTHORIZATION_GROUP_UPDATE_SAVED":
            return updateOrganisation(
                state,
                action.organisationId,
                o => ({
                    ...o,
                    authorizationGroups: [
                        ...o.authorizationGroups.map(g => g.id === action.authorizationGroupId ? {
                            ...o.updatedAuthorizationGroups[action.authorizationGroupId]
                        } : {
                            ...g
                        })
                    ],
                    updatedAuthorizationGroups: RemoveFromCollection(o.updatedAuthorizationGroups, (id) => id === action.authorizationGroupId)
                })
            );
        case "AUTHORIZATION_GROUP_DELETE_SAVED":
            return updateOrganisation(
                state,
                action.organisationId,
                o => ({
                    ...o,
                    deletedAuthorizationGroupIds: [
                        ...o.deletedAuthorizationGroupIds.filter(id => id !== action.authorizationGroupId)
                    ],
                    authorizationGroups: [
                        ...o.authorizationGroups.filter(g => g.id !== action.authorizationGroupId)
                    ]
                })
            );
        case "AUTHORIZATION_GROUP_ADD_SAVED":
            return updateOrganisation(
                state,
                action.organisationId,
                o => ({
                    ...o,
                    addedAuthorizationGroups: [
                        ...o.addedAuthorizationGroups.filter(g => g.id !== action.authorizationGroupTempId)
                    ],
                    authorizationGroups: [
                        ...o.authorizationGroups,
                        ...o.addedAuthorizationGroups
                            .filter(g => g.id === action.authorizationGroupTempId)
                            .map(g => ({
                                ...g,
                                id: action.authorizationGroupNewId
                            }))
                    ]
                })
            );
        case "SAVE_CHANGES":
            return {
                ...state,
                editingGroup: undefined
            };
        case "SHOW_ERROR_MSG":
            return {
                ...state,
                errorMsgs: [
                    ...state.errorMsgs,
                    {
                        id: Guid.create().toString(),
                        message: action.errorMsg
                    }
                ]
            }
        case "CLOSE_ERROR_MSG":
            return {
                ...state,
                errorMsgs: [
                    ...state.errorMsgs.filter(e => e.id !== action.errorMsgId)
                ]
            }
        case "INVALIDATE_DATA":
            return {
                ...initialState
            }
    }

    return state;
}

function updateOrganisation(state: OrganisationState, orgId: string, updater: (o: Organisation) => Organisation): OrganisationState {
    return {
        ...state,
        organisations: state.organisations.map(o => o.id === orgId ? {
            ...updater(o)
        } : {
            ...o
        })
    };
}

function RemoveFromCollection<T>(collection: IdCollection<T>, predicate: (id: string, o: T) => boolean): IdCollection<T> {
    return Object.keys(collection).filter(k => collection.hasOwnProperty(k))
            .reduce((obj, key) => {
                if (!predicate(key, collection[key]))
                {
                    obj[key] = { ...collection[key] };
                }
                return obj
            },
        {} as IdCollection<T>);
}