import InputCheckboxCl from './input-checkbox-cl';
import InputTextCl from './input-text-cl';
import InputDateCl from './input-date-cl';
import InputSelectCl from './input-select-cl';
import InputTextareaCl from './input-textarea-cl';
import InputRadioCl from './input-radio-cl';
import InputDropZoneCl from './input-dropzone-cl';
import InputGroupCheckboxCl from './input-group-checkbox-cl';
import InputRiskClassCl from './input-riskclass-cl';
import InputRegionCl from './input-region-cl';
import InputGeneratedCl from './input-generated-cl';
import ReflectSummaryCl from './reflect-summary-cl';
import { VFC } from 'react';

export interface IDynamicControl {
    [key: string]: VFC<any>;
}

const DynamicControls:IDynamicControl = {}
DynamicControls['checkbox-cl'] = InputCheckboxCl;
DynamicControls['text-cl'] = InputTextCl;
DynamicControls['interrest-cl'] = InputTextCl;
DynamicControls['phonenumber-cl'] = InputTextCl;
DynamicControls['date-cl'] = InputDateCl;
DynamicControls['select-cl'] = InputSelectCl;
DynamicControls['textarea-cl'] = InputTextareaCl;
DynamicControls['radio-cl'] = InputRadioCl;
DynamicControls['files-cl'] = InputDropZoneCl;
DynamicControls['group-checkbox-cl'] = InputGroupCheckboxCl;
DynamicControls['generated-cl'] = InputGeneratedCl;
DynamicControls['riskclass-cl'] = InputRiskClassCl;
DynamicControls['region-cl'] = InputRegionCl;
DynamicControls['reflect-summary-cl'] = ReflectSummaryCl;

export default DynamicControls;
