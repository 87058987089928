import React, { useEffect, useState, VFC } from 'react';
import { FieldAttributes } from 'formik';
import './input-select-cl.scss';
import { IOption, IRule } from '../../input-schema/input-schema';
import { Select } from '@in/component-library';

function generateOptions(options: IOption[]) {
    const _options: any[] = [];
    if (options !== undefined) {
        options.forEach((_option, i) => {
            _options.push(
                {key: i, value: _option.value, text: _option.text}
            )
        });
    }
    return _options;
}

const InputSelectCl: VFC<FieldAttributes<any>> = (fieldAttributes) => {
    const [options, setOptions] = useState<IOption[]>([]);
    const [selected, setSelected] = useState();

    useEffect(() => {
        const initialOptions = generateOptions(fieldAttributes.options);
        setOptions([...initialOptions]);
        if (fieldAttributes.form.values[fieldAttributes.field.name] !== undefined && fieldAttributes.form.values[fieldAttributes.field.name].length > 0) {
            setSelected(fieldAttributes.form.values[fieldAttributes.field.name]);
        }
    },[fieldAttributes.form.values, fieldAttributes.field.name, fieldAttributes.options])

    // Rules must be applied for every change regardless if it has been done by the user or by other events
    useEffect(() => {
        // Selects might have rules to manipulate other fields, so execute them here
        if (fieldAttributes.rules !== undefined) {
            fieldAttributes.rules.forEach((rule: IRule) => {
                if (fieldAttributes.form.values[fieldAttributes.field.name] === rule.onchangeto) {
                    rule.actions.forEach((action) => {
                        if (action.property === 'value') {
                            if (action.exceptwhen !== undefined) {
                                // This number must not be reset if it is divisible by 12 or, else we
                                // will reset an earlier validated value when reinitializing through
                                // re-authenticate. Similarly, it must be reset if a user has
                                // set it to a number not divisible by 12.
                                if (action.exceptwhen === 'divisionby12isok' && !isNaN(action.value)) {
                                    const checkdivisionby12: boolean = (+action.value % 12) === 0;
                                    if (!checkdivisionby12) {
                                        fieldAttributes.form.setFieldValue(action.id, action.value, true);
                                    }
                                }
                            } else {
                                fieldAttributes.form.setFieldValue(action.id, action.value, true);
                            }

                            // fieldAttributes.form.setFieldTouched(action.id, true, true);
                        }
                    });
                }
            });
        }

        if(selected !== fieldAttributes.form.values[fieldAttributes.field.name]) {
            setSelected(fieldAttributes.form.values[fieldAttributes.field.name]);
        }
    }, [fieldAttributes.form.values, fieldAttributes.field.name, fieldAttributes.rules, fieldAttributes.form.values[fieldAttributes.field.name]]);


    const handleSelect = (e: any) => {
        // For some reason, the component library returns a parameter that is either null or a string now. Expected was an event.
        if(fieldAttributes === undefined || fieldAttributes.form === undefined || fieldAttributes.form.values === undefined) {
            return;
        }
        if(e === null || e === undefined) {
            return;
        }
        // // The first is just to make sure the form.values has the updated value in time for setFieldTouched
        if(e !== fieldAttributes.form.values[fieldAttributes.field.name]) {

            fieldAttributes.form.values[fieldAttributes.field.name] = e;
            // This is async internally, but should be called
            fieldAttributes.form.setFieldValue(fieldAttributes.field.name, e);
            // Set field touched, and validated
            fieldAttributes.form.setFieldTouched (fieldAttributes.field.name, true, true);
        }
    };

    function disabled(readonly: boolean) {
        return readonly === true ? true : undefined;
    }

    function showError() {
        if(fieldAttributes.form.touched[fieldAttributes.field.name] && fieldAttributes.form.errors[fieldAttributes.field.name]) {
            return fieldAttributes.form.errors[fieldAttributes.field.name]
        }
        return undefined
    }

    return (
        <Select
            key={fieldAttributes.field.name}
            name={fieldAttributes.field.name}
            label={fieldAttributes.label} 
            errorMsg={showError()} 
            options={options}
            onChange={handleSelect}
            disabled={disabled(fieldAttributes.readonly)}
            value={selected}
        />
    );
}

export default InputSelectCl;
