import React, { VFC } from 'react';
import './input-textarea-cl.scss';
import { FieldAttributes } from 'formik';
import { TextArea } from '@in/component-library';

const InputTextareaCl: VFC<FieldAttributes<any>> = (fieldAttributes) => {
    function showInfo() {
        return fieldAttributes.texts !== undefined && fieldAttributes.texts.info !== undefined 
    }

    function showError() {
        if((fieldAttributes.form.touched[fieldAttributes.field.name] === true) && (fieldAttributes.form.errors[fieldAttributes.field.name] !== undefined)) {
            return fieldAttributes.form.errors[fieldAttributes.field.name]
        }
        return undefined
    }

    return (
    <>
        {showInfo() && (
                    <div className="textareaInfoCl">{fieldAttributes.texts.info}</div>
        )}
        <TextArea
            autoResize={true}
            value={fieldAttributes.form.values[fieldAttributes.field.name]}
            name={fieldAttributes.field.name}
            label={fieldAttributes.label} 
            placeholder={fieldAttributes.placeholder} 
            rows={5}
            maxCount={1000}
            errorMsg={showError()}
            onChange={(e:any) => {
                fieldAttributes.form.values[fieldAttributes.field.name] = e.target.value;
                fieldAttributes.form.validateField(fieldAttributes.field.name, true);
            }}
        />
    </>
    )

}

export default InputTextareaCl;
