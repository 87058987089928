import React, { useState, useEffect, useContext, VFC } from 'react';
import { FieldAttributes } from 'formik';
import './input-region-cl.scss';
import { IOption } from '../../input-schema/input-schema';
import { UserContext, ILoggedOnUser } from '../../../utils/auth-context';
import { Select, InputField } from '@in/component-library';

const InputRegion: VFC<FieldAttributes<any>> = (fieldAttributes) => {
    const userContext: ILoggedOnUser = useContext(UserContext);

    const [options, setOptions] = useState<IOption[]>([]);
    const [selected, setSelected] = useState('');

    useEffect(() => {
        if (fieldAttributes.form.values[fieldAttributes.field.name] !== undefined && fieldAttributes.form.values[fieldAttributes.field.name].length > 0) {
            // If the value is allready set earlier. Update selected to its value
            setSelected(fieldAttributes.form.values[fieldAttributes.field.name]);
        }
        else if (fieldAttributes.contactinformation[fieldAttributes.field.name] !== undefined && fieldAttributes.form.values[fieldAttributes.field.name] !== fieldAttributes.contactinformation[fieldAttributes.field.name]) {
            // Else, set the value to its contactinformation value
            fieldAttributes.form.setFieldValue(fieldAttributes.field.name, fieldAttributes.contactinformation[fieldAttributes.field.name], true, true);

            // And set selected
            if (selected === undefined || (selected as string).length === 0) {
                setSelected(fieldAttributes.contactinformation[fieldAttributes.field.name]);
            }
        }
    }, [fieldAttributes.contactinformation, fieldAttributes.field.name, fieldAttributes.form, selected]);

    // Start
    useEffect(() => {
        const authGroups = userContext.user.groups;
        if (authGroups !== undefined && authGroups.length > 0) {
            let opt:IOption[] = [];
            opt.push({ key: '0', value: '', text: '- Velg alternativ' } as IOption);

            opt = opt.concat(
                authGroups
                    .filter((authGroup) => authGroup.isApplicantGroup)
                    .map(
                        (r, i) =>
                            ({
                                key: `${i + 1}`,
                                value: `${r.displayName}`,
                                text: `${r.displayName}`,
                            } as IOption)
                    )
            );

            setOptions([...opt]);
            if (fieldAttributes.form.values[fieldAttributes.field.name] !== undefined && fieldAttributes.form.values[fieldAttributes.field.name].length > 0) {
                setSelected(fieldAttributes.form.values[fieldAttributes.field.name]);
            } else {
                setSelected(fieldAttributes.contactinformation[fieldAttributes.field.name]);
            }
        }
    }, [fieldAttributes.form.values, fieldAttributes.field.name, fieldAttributes.contactinformation[fieldAttributes.field.name]]);

    const handleSelect = (e: any) => {
        // For some reason, the component library returns a parameter that is either null or a string now. Expected was an event.
        if (fieldAttributes === undefined || fieldAttributes.form === undefined || fieldAttributes.form.values === undefined) {
            return;
        }
        if (e === null || e === undefined) {
            return;
        }
        // // The first is just to make sure the form.values has the updated value in time for setFieldTouched
        if (e !== fieldAttributes.form.values[fieldAttributes.field.name]) {
            fieldAttributes.form.values[fieldAttributes.field.name] = e;
            // This is async internally, but should be called
            fieldAttributes.form.setFieldValue(fieldAttributes.field.name, e);
            // Set field touched, and validated
            fieldAttributes.form.setFieldTouched(fieldAttributes.field.name, true, true);
        }
    };

    function disabled(readonly: boolean) {
        return readonly === true ? true : undefined;
    }
    function showError() {
        if (fieldAttributes.form.touched[fieldAttributes.field.name] && fieldAttributes.form.errors[fieldAttributes.field.name]) {
            return fieldAttributes.form.errors[fieldAttributes.field.name];
        }
        return undefined;
    }

    return options.length > 0 ? (
        <div className="OverrideCLLastChildBorder"><Select name={fieldAttributes.field.name} 
        label={fieldAttributes.label} 
        errorMsg={showError()} 
        options={options} 
        onChange={handleSelect} 
        disabled={disabled(fieldAttributes.readonly)} 
        value={selected} /></div>
    ) : (
        <div className="OverrideCLLastChildBorder"><InputField
            name={fieldAttributes.field.name}
            label={fieldAttributes.label}
            placeholder={fieldAttributes.placeholder}
            disabled={disabled(fieldAttributes.readonly)}
            value={fieldAttributes.form.values[fieldAttributes.field.name]}
            errorMsg={showError()}
        /></div>
    );
}

export default InputRegion;
