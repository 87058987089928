import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { LabelsContext } from '../../utils/labels-context';
import * as OrganisationDetailsStore from '../../store/OrganisationDetails';
import * as OrganisationStore from '../../store/Organisation';
import Button from '../button';
import FaIcon from '../fa-icon';
import { Guid } from 'guid-typescript';
import { History } from 'history';
import { withRouter, NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import './organisation-details.scss';
import { MessageContext } from '../../utils/message-context/message-context';

type OrganisationDetailsProps = OrganisationDetailsStore.OrganisationDetailsState & { organisationDetailsActions: typeof OrganisationDetailsStore.actionCreators } & { organisationActions: typeof OrganisationStore.actionCreators } & {
    id: string | undefined;
} & { history: History };

class OrganisationDetails extends React.PureComponent<OrganisationDetailsProps> {
    public componentDidMount() {
        if (this.props.id) {
            this.props.organisationDetailsActions.requestOrganisation(this.props.id);
        } else {
            this.props.organisationDetailsActions.initilizeOrganisation();
        }
    }

    /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
    public render() {
        const organisation = this.props.updates || this.props.organisation;
        return (
            <React.Fragment>
                <MessageContext.Consumer>
                    {(msgs) => {
                        if (this.props.error) {
                            msgs.handleAPIError(`En feil oppsto under kommunikasjonen med tjenersiden. ${this.props.error}`, undefined);
                        }
                        return (
                            <LabelsContext.Consumer>
                                {(labels) => (
                                    <div className={'content-area form-content'}>
                                        <NavLink className="button" to="/organisation">
                                            {labels.back}
                                        </NavLink>

                                        {organisation && (
                                            <div className={'top-m-10'}>
                                                <Button onClick={() => this.saveOrganisation()} disabled={this.props.updates === undefined && !this.props.markedForDeletion} theme={this.props.updates !== undefined ? 'green' : 'gray'} type="submit">
                                                    {labels.saveChanges}
                                                </Button>
                                                &nbsp;
                                                {this.props.id !== Guid.createEmpty().toString() && (
                                                    <Button disabled={this.props.markedForDeletion} theme={this.props.markedForDeletion ? 'gray' : 'red'} onClick={() => this.props.organisationDetailsActions.markForDeletion()}>
                                                        {labels.Delete} {labels.organisation}
                                                    </Button>
                                                )}
                                                &nbsp;
                                                {(this.props.updates !== undefined || this.props.markedForDeletion) && (
                                                    <Button theme="gray" onClick={() => this.props.organisationDetailsActions.restoreOrganisation()}>
                                                        {labels.restore}
                                                    </Button>
                                                )}
                                                <div className={'form-field'}>
                                                    <label className={'form-label'}>{labels.OrganisationNumber}:</label>
                                                    <input
                                                        autoComplete="chrome"
                                                        value={organisation.organisationNumber || ''}
                                                        disabled={this.props.markedForDeletion || this.props.isLoading}
                                                        onChange={(e) => this.updateOrganisation({ organisationNumber: e.target.value })}
                                                        onBlur={(e) => this.props.organisationDetailsActions.loadOrganisationRegistryData(e.target.value)}
                                                    ></input>
                                                </div>
                                                <div className={'form-field'}>
                                                    <label className={'form-label'}>{labels.displayName}:</label>
                                                    <input
                                                        autoComplete="chrome"
                                                        value={organisation.displayName}
                                                        disabled={this.props.markedForDeletion || this.props.isLoading}
                                                        onChange={(e) => this.updateOrganisation({ displayName: e.target.value })}
                                                    ></input>
                                                </div>
                                                <div className={'form-field'}>
                                                    <label className={'form-label'}>{labels.address}:</label>
                                                    <input
                                                        autoComplete="chrome"
                                                        value={organisation.address}
                                                        disabled={this.props.markedForDeletion || this.props.isLoading}
                                                        onChange={(e) => this.updateOrganisation({ address: e.target.value })}
                                                    ></input>
                                                </div>
                                                <div className={'form-field'}>
                                                    <label className={'form-label'}>{labels.postCode}:</label>
                                                    <input
                                                        autoComplete="chrome"
                                                        value={organisation.postCode}
                                                        disabled={this.props.markedForDeletion || this.props.isLoading}
                                                        onChange={(e) => this.updateOrganisation({ postCode: e.target.value })}
                                                    ></input>
                                                </div>
                                                <div className={'form-field'}>
                                                    <label className={'form-label'}>{labels.city}:</label>
                                                    <input autoComplete="chrome" value={organisation.city} disabled={this.props.markedForDeletion || this.props.isLoading} onChange={(e) => this.updateOrganisation({ city: e.target.value })}></input>
                                                </div>
                                                <div className={'form-field'}>
                                                    <label className={'form-label'}>{labels.phoneNumber}:</label>
                                                    <input
                                                        autoComplete="chrome"
                                                        value={organisation.phoneNumber}
                                                        disabled={this.props.markedForDeletion || this.props.isLoading}
                                                        onChange={(e) => this.updateOrganisation({ phoneNumber: e.target.value })}
                                                    ></input>
                                                </div>
                                                <div className={'form-field'}>
                                                    <label className={'form-label'}>{labels.tenantId}:</label>
                                                    <input
                                                        autoComplete="chrome"
                                                        value={organisation.tenantId}
                                                        disabled={this.props.markedForDeletion || this.props.isLoading}
                                                        onChange={(e) => this.updateOrganisation({ tenantId: e.target.value })}
                                                    ></input>
                                                </div>
                                                Risikoklasser:
                                                {organisation.riskScoreMappings
                                                    .sort((a, b) => a.index - b.index)
                                                    .map((m, i) => (
                                                        <div className={'form-field risk-classes'} key={i}>
                                                            <input
                                                                autoComplete="chrome"
                                                                disabled={this.props.markedForDeletion || this.props.isLoading}
                                                                value={m.bankRiskScore}
                                                                onChange={(e) => {
                                                                    this.updateOrganisation({
                                                                        riskScoreMappings: [
                                                                            ...organisation!.riskScoreMappings.slice(0, i),
                                                                            { bankRiskScore: e.target.value, eifRiskScore: m.eifRiskScore, index: i },
                                                                            ...organisation!.riskScoreMappings.slice(i + 1),
                                                                        ],
                                                                    });
                                                                }}
                                                            />
                                                            <select
                                                                disabled={this.props.markedForDeletion || this.props.isLoading}
                                                                className="left-m-10 select"
                                                                value={m.eifRiskScore}
                                                                onChange={(e) => {
                                                                    this.updateOrganisation({
                                                                        riskScoreMappings: [
                                                                            ...organisation!.riskScoreMappings.slice(0, i),
                                                                            { bankRiskScore: m.bankRiskScore, eifRiskScore: e.target.value, index: i },
                                                                            ...organisation!.riskScoreMappings.slice(i + 1),
                                                                        ],
                                                                    });
                                                                }}
                                                            >
                                                                <option>A</option>
                                                                <option>B</option>
                                                                <option>C</option>
                                                            </select>
                                                            {!this.props.markedForDeletion && (
                                                                <div
                                                                    className={'iconButton small-iconButton left-m-20'}
                                                                    onClick={() =>
                                                                        this.updateOrganisation({
                                                                            ...organisation,
                                                                            riskScoreMappings: [...organisation!.riskScoreMappings.slice(0, i), ...organisation!.riskScoreMappings.slice(i + 1).map((m, index) => ({ ...m, index: i + index }))],
                                                                        })
                                                                    }
                                                                >
                                                                    <FaIcon name="trash" />
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                {!this.props.markedForDeletion && (
                                                    <div
                                                        className={'iconButton small-iconButton add-button'}
                                                        onClick={() =>
                                                            this.updateOrganisation({
                                                                ...organisation,
                                                                riskScoreMappings: [...organisation!.riskScoreMappings, { bankRiskScore: '', eifRiskScore: 'A', index: organisation!.riskScoreMappings.length }],
                                                            })
                                                        }
                                                    >
                                                        <FaIcon name="plus-circle" />
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </LabelsContext.Consumer>
                        );
                    }}
                </MessageContext.Consumer>
            </React.Fragment>
        );
    }

    updateOrganisation(updates: any): any {
        console.debug('updateOrganisation', updates);
        const organisation = this.props.updates || this.props.organisation;
        this.props.organisationDetailsActions.updateOrganisation({
            ...organisation!,
            ...updates,
        });
    }

    saveOrganisation(): any {
        this.props.organisationDetailsActions.saveOrganisation();
        this.props.organisationActions.invalidateData();
    }
}

function mapStateToProps(state: ApplicationState): any {
    return state.organisationDetails;
}

function mapDispatchToProps(dispatch: any): any {
    return {
        organisationDetailsActions: bindActionCreators(OrganisationDetailsStore.actionCreators, dispatch),
        organisationActions: bindActionCreators(OrganisationStore.actionCreators, dispatch),
    };
}

export default withRouter(connect((state: ApplicationState) => mapStateToProps(state), mapDispatchToProps)(OrganisationDetails as any));
