import React, { useState, useEffect, useContext, VFC } from 'react';
import { useField, FieldAttributes } from 'formik';
import './input-riskclass-cl.scss';
import { IOption } from '../../input-schema/input-schema';
import { UserContext, ILoggedOnUser } from '../../../utils/auth-context';
import { Select, InputField } from '@in/component-library';


const InputRiskClassCl: VFC<FieldAttributes<any>> = (fieldAttributes) => {
    const [field] = useField(fieldAttributes.field.name);

    const userContext: ILoggedOnUser = useContext(UserContext);

    const [options, setOptions] = useState<IOption[]>([]);
    const [selected, setSelected] = useState('');


    useEffect(() => {
        if(selected !== fieldAttributes.form.values[fieldAttributes.field.name]) {
            setSelected(fieldAttributes.form.values[fieldAttributes.field.name]);
        }
    }, [fieldAttributes.form.values[fieldAttributes.field.name]]);

    useEffect(() => {
        const riskScoreMappings = userContext.user.organisation.riskScoreMappings;
        if (riskScoreMappings !== undefined && riskScoreMappings.length > 0) {
            
            options.push({ key: '0', value: '', text: '- Velg alternativ' } as IOption);

            const optionsFiltered = options
                .concat(riskScoreMappings
                    .sort((a, b) => a.index - b.index)
                    .map((r, i) => ({ 
                        key: `${i+1}`,
                        value: `Banken: ${r.bankRiskScore} EIF: ${r.eifRiskScore}`,
                        text: `${r.bankRiskScore}`
                    } as IOption)));

                    setOptions([...optionsFiltered]);
                    if (fieldAttributes.form.values[fieldAttributes.field.name] !== undefined && fieldAttributes.form.values[fieldAttributes.field.name].length > 0) {
                        // console.log('Setting initial select to modified value :' + fieldAttributes.form.values[fieldAttributes.field.name]);
                        setSelected(fieldAttributes.form.values[fieldAttributes.field.name]);
                    } else {
                        // console.log('Setting initial select to initial value :' + fieldAttributes.contactinformation[fieldAttributes.field.name]);
                        setSelected('');
                    }
                }
    }, []);

    const handleSelect = (e: any) => {
        // For some reason, the component library returns a parameter that is either null or a string now. Expected was an event.
        if(fieldAttributes === undefined || fieldAttributes.form === undefined || fieldAttributes.form.values === undefined) {
            return;
        }
        if(e === null || e === undefined) {
            return;
        }
        // // The first is just to make sure the form.values has the updated value in time for setFieldTouched
        if(e !== fieldAttributes.form.values[fieldAttributes.field.name]) {
            fieldAttributes.form.values[fieldAttributes.field.name] = e;
            // This is async internally, but should be called
            fieldAttributes.form.setFieldValue(fieldAttributes.field.name, e);
            // Set field touched, and validated
            fieldAttributes.form.setFieldTouched (fieldAttributes.field.name, true, true);
        }
    };

    function disabled(readonly: boolean) {
        return readonly === true ? true : undefined;
    }
    const handleChange = (e: any) => {
        field.onChange(e);
    };

    function showError() {
        if(fieldAttributes.form.touched[fieldAttributes.field.name] && fieldAttributes.form.errors[fieldAttributes.field.name]) {
            return fieldAttributes.form.errors[fieldAttributes.field.name]
        }
        return undefined
    }



    return (
        options.length > 0 ? (
            <div className="OverrideCLLastChildBorder"><Select
                name={fieldAttributes.field.name} 
                label={fieldAttributes.label} 
                errorMsg={showError()} 
                options={options} 
                onChange={handleSelect}
                disabled={disabled(fieldAttributes.readonly)}
                value={selected}

                /></div>
        ) : (
            <div className="OverrideCLLastChildBorder"><InputField
                name={fieldAttributes.field.name} label={fieldAttributes.label} 
                placeholder={fieldAttributes.placeholder} 
                onChange={handleChange} 
                disabled={disabled(fieldAttributes.readonly)}
                value={fieldAttributes.form.values[fieldAttributes.field.name]}
                errorMsg={showError()}
            /></div>
        )
    );
}

export default InputRiskClassCl;
