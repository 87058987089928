import { useState, useEffect } from 'react';
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';

const useResponsiveView: any = () => {
    const [width, setWidth] = useState<number>(window.innerWidth);

    useEffect(() => {
        const resizeEvent = fromEvent(window, 'resize');
        const windowWidth = resizeEvent.pipe(map((event: any) => (event.target !== null ? event.target.innerWidth : 0)));
        const subscription = windowWidth.subscribe((width) => {
            setWidth(width);
        });
        return () => {
            subscription.unsubscribe();
        };
    }, []);

    return { width };
};

export { useResponsiveView };
