import React, { useEffect, useState, useContext } from 'react';
import Axios from 'axios-observable';
import { delay, take } from 'rxjs/operators';
import { ShowMessages, MessageContext } from './message-context/message-context';
import { Header, Body, Footer } from '@in/component-library';
import { LabelsContext } from './labels-context';
import './auth-context.scss';
import eifLogo from '../assets/icons/logo_eif.svg';
import { useHistory } from 'react-router-dom';

import moment from 'moment';

const EifFeatureFlag = false;

enum GetUserStatus {
    LOGGED_OUT = 0,
    LOGGED_IN = 1,
    WAITING = 2,
    SERVICE_ERROR = 3,
    NOT_IMPLEMENTED = 4,
}

interface IUser {
    displayName: string;
    emailAddress: string;
    groups: IUserGroup[] | undefined;
    isINAdmin: boolean;
    metaData: { [id: string]: string };
    organisation: IUserOrganisation;
}

interface IUserGroup {
    displayName: string;
    id: string;
    isAdmin: boolean;
    isApplicantGroup: boolean;
    organisationId: string;
    remoteId: string;
}

interface IRiskScore {
    bankRiskScore: string;
    eifRiskScore: string;
    index: number;
}

interface IUserOrganisation {
    address: string;
    city: string;
    displayName: string;
    id: string;
    organisationNumber: string;
    phoneNumber: string;
    postCode: string;
    riskScoreMappings: IRiskScore[] | undefined;
    tenantId: string;
}

export interface ILoggedOnUser {
    identityProvider: string;
    loginTimeStamp: number;
    status: GetUserStatus;
    user: IUser;
    dispatch(action: string): void;
}

const INITIAL_USER: IUser = {
    displayName: '',
    emailAddress: '',
    groups: [],
    isINAdmin: false,
    metaData: {},
    organisation: { id: '', displayName: '', organisationNumber: '', address: '', postCode: '', city: '', phoneNumber: '', tenantId: '', riskScoreMappings: [] },
};

/* eslint-disable @typescript-eslint/no-empty-function */
const INITIAL_LOGGEDON_USER: ILoggedOnUser = {
    identityProvider: '',
    loginTimeStamp: moment.now(),
    status: GetUserStatus.LOGGED_OUT,
    user: INITIAL_USER,
    dispatch() {},
};

const UserContext = React.createContext<ILoggedOnUser>(INITIAL_LOGGEDON_USER);

function UserProviderWrapper(props: { children: React.ReactNode }): JSX.Element {
    const [loggedOnUser, setLoggedOnUser] = useState<ILoggedOnUser>(INITIAL_LOGGEDON_USER);
    const messageContext = useContext(MessageContext);
    const labelsContext = useContext(LabelsContext);

    const menuItems = undefined;
    const menuItemsLoggedIn = [{ text: labelsContext.logout, href: '/logoutpage' }];
    const menuItemsLoggedInAdmin = [
        { text: labelsContext.logout, href: '/logoutpage' },
        { text: labelsContext.adminTools, href: '/adminpage' },
    ];

    const history = useHistory();

    const navItems = [
        {
            text: 'Søknadsoversikt',
            onClick: (e) => {
                history.push('/applicationlistpage');
                e.preventDefault();
            },
            href: undefined,
        },
        {
            text: 'Prekvalifisering',
            onClick: (e) => {
                history.push('/prequalification');
                e.preventDefault();
            },
            href: undefined,
        },
    ];

    useEffect(() => {
        let status = GetUserStatus.LOGGED_OUT;
        let user: IUser = INITIAL_USER;
        setLoggedOnUser({ ...loggedOnUser, user: INITIAL_USER, identityProvider: 'AAD', status: GetUserStatus.WAITING, loginTimeStamp: moment.now() });
        Axios.get('/api/session/getuser')
            .pipe((errors) => errors.pipe(delay(1000), take(3)))
            .subscribe(
                (response) => {
                    if (response.status === 200) {
                        user = response.data as IUser;
                        status = GetUserStatus.LOGGED_IN;
                    } else if (response.status === 204) {
                        user = INITIAL_USER;
                        status = GetUserStatus.LOGGED_OUT;
                    } else {
                        user = INITIAL_USER;
                        status = GetUserStatus.NOT_IMPLEMENTED;
                        messageContext.addMessage({ message: 'Uventet respons', tooltip: `${JSON.stringify(response)}`, type: 'error', view: 'approot' });
                    }
                },
                (error) => {
                    console.error(error);
                    messageContext.addMessage({ message: 'Teknisk feil ved pålogging', tooltip: `${JSON.stringify(error.response)}`, type: 'error', view: 'approot' });
                    setLoggedOnUser({ ...loggedOnUser, user: INITIAL_USER, identityProvider: 'AAD', status: GetUserStatus.SERVICE_ERROR, loginTimeStamp: moment.now() });
                },
                () => {
                    const immutableLoggedOnUser = {
                        ...loggedOnUser,
                        user: user,
                        identityProvider: 'AAD',
                        status: status,
                        timeStamp: moment.now(),
                        dispatch(action: string) {
                            if (action === 'logout') {
                                setLoggedOnUser({ ...loggedOnUser, user: INITIAL_USER, identityProvider: 'AAD', status: GetUserStatus.LOGGED_OUT, loginTimeStamp: moment.now() });
                                document.location.href = '/';
                            }
                        },
                    };
                    setLoggedOnUser(immutableLoggedOnUser);
                }
            );
    }, []);

    function loggedIn() {
        return loggedOnUser.status === GetUserStatus.LOGGED_IN;
    }
    function loggedInAdmin() {
        return loggedOnUser.status === GetUserStatus.LOGGED_IN && (loggedOnUser.user!.groups!.some((g) => g.isAdmin) || loggedOnUser.user!.isINAdmin);
    }

    return (
        <UserContext.Provider value={loggedOnUser}>
            {!loggedIn() && <Header logoAriaLabel="Til forsiden" logoLink="#" name={loggedOnUser.user.displayName} menuItems={menuItems} loggedInAsLabel="Innlogget som:" />}
            {loggedIn() && !loggedInAdmin() && <Header navItems={navItems} logoAriaLabel="Til forsiden" logoLink="#" name="Bruker" menuItems={menuItemsLoggedIn} loggedInAsLabel="Innlogget som:" />}
            {loggedInAdmin() && <Header navItems={navItems} logoAriaLabel="Til forsiden" logoLink="#" name={loggedOnUser.user.displayName} menuItems={menuItemsLoggedInAdmin} loggedInAsLabel="Innlogget som:" />}

            <Body className="siteBody">
                <div className="pageContent">
                    <div className="messageWrapperTop">
                        <ShowMessages className="messagesAppRoot" view="approot" />
                    </div>
                    {props.children}
                </div>
            </Body>
            <Footer childrenStartsOnFirstRow={true}>
                {EifFeatureFlag ? (
                    <a href="https://www.eif.org/" title="Link to EIF" target="_blank" rel="noopener noreferrer" className="noUnderline">
                        <img src={eifLogo} alt="EIF logo" width={170} className="noUnderline"></img>
                    </a>
                ) : (
                    <></>
                )}
                <div className="visibleLink">
                    <h4>
                        Spørsmål angående pre-kvalifisering,
                        <br />
                        saksbehandling eller utfylling av skjema?
                    </h4>
                    <p>
                        Kontakt Innovasjon Norges rådgivere på:
                        <br />
                        <a target="_blank" rel="noopener noreferrer" href="mailto:vekstgaranti@innovasjonnorge.no">
                            vekstgaranti@innovasjonnorge.no
                        </a>
                        .
                    </p>
                </div>
                <div className="visibleLink">
                    <h4>
                        Annen informasjon som kan være nyttig
                        <br />å få med seg:
                    </h4>
                    <p>
                        Dette nettstedet bruker{' '}
                        <a target="_blank" rel="noopener noreferrer" href="https://www.innovasjonnorge.no/no/privacydeclaration/cookies">
                            cookies
                        </a>
                        .<br />
                        Se vår{' '}
                        <a target="_blank" rel="noopener noreferrer" href="https://www.innovasjonnorge.no/no/privacydeclaration/">
                            personvernerklæring
                        </a>
                        .
                    </p>
                    <p> 
                        <a href="https://uustatus.no/nb/erklaringer/publisert/29970c7e-6d74-4326-88dc-73557455bfc1" rel="nofollow noreferrer noopener" target="_blank">Tilgjengelighetserklæring</a>
                    </p>
                </div>
            </Footer>
        </UserContext.Provider>
    );
}
const UserConsumer = UserContext.Consumer;
export { UserProviderWrapper, UserConsumer, UserContext, INITIAL_USER, GetUserStatus };
