import React, { useEffect, useState, VFC } from 'react';
import { FieldAttributes } from 'formik';
import { Checkbox } from '@in/component-library';
import './input-checkbox-cl.scss';

export interface Option {
    value: string;
    text: string;
    selected: boolean;
}

const InputCheckboxCl: VFC<FieldAttributes<any>> = (fieldAttributes) => {
    const initialOption: Option = {
        text: fieldAttributes.label,
        selected: false,
        value: 'valueok',
    };

    useEffect(() => {
        if (fieldAttributes.form.values && fieldAttributes.field.name && fieldAttributes.form.values[fieldAttributes.field.name]) {
            if (fieldAttributes.form.values[fieldAttributes.field.name].includes('valueok')) {
                setOptions([{ ...initialOption, selected: true }]);
            } else {
                setOptions([{ ...initialOption, selected: false }]);
            }
        } else {
            setOptions([{ ...initialOption, selected: false }]);
        }
    }, [fieldAttributes.form.values, fieldAttributes.field.name, fieldAttributes.form.values[fieldAttributes.field.name]]);

    const [options, setOptions] = useState([initialOption]);

    function showError() {
        if (fieldAttributes.form.touched[fieldAttributes.field.name] && fieldAttributes.form.errors[fieldAttributes.field.name]) {
            return fieldAttributes.form.errors[fieldAttributes.field.name];
        }
        return undefined;
    }

    return (
        <Checkbox
            name="checkbox"
            options={options}
            errorMsg={showError()}
            onChange={(e: any): void => {
                // Internal CL state
                setOptions(
                    options.map((option) => ({
                        ...option,
                        selected: e.currentTarget.value === option.value ? e.currentTarget.checked : option.selected,
                    }))
                );

                // Update Formik
                // handleChange(e);
                options.forEach((option) => {
                    if (e.currentTarget.value === option.value) {
                        if (e.currentTarget.checked === true) {
                            fieldAttributes.form.values[fieldAttributes.field.name] = ['valueok'];
                            fieldAttributes.form.setFieldValue(fieldAttributes.field.name, ['valueok']);
                            fieldAttributes.form.validateField(fieldAttributes.field.name);
                        } else {
                            fieldAttributes.form.values[fieldAttributes.field.name] = [];
                            fieldAttributes.form.setFieldValue(fieldAttributes.field.name, []);
                            fieldAttributes.form.validateField(fieldAttributes.field.name);
                        }
                    }
                });
            }}
            onBlur={fieldAttributes.form.handleBlur}
        />
    );
};

export default InputCheckboxCl;
