import Axios from 'axios-observable';
import { IValue } from './input-schema';
import { timer } from 'rxjs';

interface IOrganisationRegistryEntry {
    city: string;
    country: string;
    nace: string;
    name: string;
    orgId: string;
    street: string;
    zip: string;
}

export interface CallbackFunction {
    name: string;
    func: (...args: any[]) => void;
}

let previousOrgNumber: string;
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function resetOrgNumber() {
    previousOrgNumber = '';
}

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const Callbacks: CallbackFunction[] = [
    {
        name: 'fetchOrganisationInfo',
        func: (
            event: any,
            schemaField: IValue,
            setValue: (name: string, value: any) => void,
            setWrongOrgNumber: (value: boolean) => void,
            validateField: (field: string) => void,
            nameFieldId: string,
            addressFieldId: string,
            postalCodeFieldId: string,
            placeFieldId: string,
            sectorFieldId: string
        ) => {
            // Only update if the org number actually changed.
            if (previousOrgNumber !== event.target.value) {
                const value = event.target.value as string;

                const observeOrgReg = {
                    next: (r: any) => {
                        previousOrgNumber = value;
                        if (r.status === 200) {
                            const orgEntry = r.data as IOrganisationRegistryEntry;
                            setValue(nameFieldId, orgEntry.name || '');
                            setValue(addressFieldId, orgEntry.street || '');
                            setValue(postalCodeFieldId, orgEntry.zip || '');
                            setValue(placeFieldId, orgEntry.city || '');
                            setValue(sectorFieldId, orgEntry.nace || '');
                            setWrongOrgNumber(false);
                            timer(50).subscribe(() => {
                                try {
                                    validateField(schemaField.id);
                                } catch (ex) {
                                    console.warn('The page was left before a committed validation could finish. This is safe to ignore.');
                                }
                            });
                        }
                    },
                    error: (e: any) => {
                        previousOrgNumber = value;
                        console.error(e.response);
                        setValue(nameFieldId, '');
                        setValue(addressFieldId, '');
                        setValue(postalCodeFieldId, '');
                        setValue(placeFieldId, '');
                        setValue(sectorFieldId, '');
                        setWrongOrgNumber(true);
                        timer(50).subscribe(() => {
                            try {
                                validateField(schemaField.id);
                            } catch (ex) {
                                console.warn('The page was left before a committed validation could finish. This is safe to ignore.');
                            }
                        });
                    },
                };

                if (value.match(/[0-9]{9}/)) {
                    Axios.get(`/api/organisationregistry/${value}`).subscribe(observeOrgReg);
                }
            }
        },
    },
];
